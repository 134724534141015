import React from 'react';
import { Link } from 'react-router-dom';
import { ChildrenOwnerProps } from '../Base';

interface FooterLinkProps extends ChildrenOwnerProps {
  to?: string;
}

export function FooterLink(props: FooterLinkProps) {
  return (
    <li className="mb-2 cursor-pointer hover:underline">
      {props.to ? <Link to={props.to}>{props.children}</Link> : props.children}
    </li>
  );
}

export function FooterLinksWrapper(props: ChildrenOwnerProps) {
  return <ul className="mx-3 text-white">{props.children}</ul>;
}
