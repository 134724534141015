import React from 'react';
import BorderedContainer from '../../components/Containers/BorderedContainer';
import { ExpirationMessage, LicenseDTO, RenewingAtMessage } from './CompanyMember';
import logo from '../../assets/mint_medical_logo.svg';
import { useProductData } from '../../hooks/useProductData';
import { DownloadBorderedButton } from '../DownloadButtons/DownloadBorderedButton';
import { DownloadFilledButton } from '../DownloadButtons/DownloadFilledButton';

export interface CompanyMemberLicenseProps {
  license: LicenseDTO;
}

export function CompanyMemberLicense(props: CompanyMemberLicenseProps) {
  const license = props.license;

  const { productData } = useProductData({
    productId: license.product_id,
    lang: 'en'
  });

  return (
    <>
      <BorderedContainer key={license.license_id}>
        <div className="flex flex-col md:flex-row-reverse">
          <div className=" flex flex-col">
            <span className="font-semibold">License Number:</span>
            <span className="font-semibold">{license.license_id.replace('sub_', '')}</span>
          </div>

          <div className="flex flex-col gap-4 py-4 md:w-full md:flex-row">
            <div className="w-full md:w-1/6">
              <img
                // className="w-full md:w-1/12"
                src={logo}></img>
            </div>

            <div className="flex flex-col">
              <span className="font-semibold">{productData?.name ? productData.name : ''}</span>
              <span>1 Seat</span>
              <div className="flex flex-row space-x-1">
                <span>Subscription status:</span>
                <span
                  className={license.status.toLowerCase() === 'active' ? ' text-mint-medical-green' : ' text-red-500'}>
                  {license.status.toUpperCase()}
                </span>
              </div>
              <div className="flex flex-row space-x-1 text-base">
                <span>Date of Approval of Order Request:</span>
                <span className="my-auto">{new Date(license.start_date * 1000).toLocaleDateString()}</span>
              </div>
              <div className="flex flex-row space-x-1 text-base">
                {license.cancel_at ? (
                  <ExpirationMessage endDateTimestamp={license.cancel_at}></ExpirationMessage>
                ) : (
                  <RenewingAtMessage renewingTimestamp={license.period_end_date}></RenewingAtMessage>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-end">
          <div className=" flex w-full flex-col content-end gap-4 self-end md:w-1/2 md:flex-row">
            <DownloadBorderedButton
              fileName={productData.manualFileName!}
              label={'Download manual'}></DownloadBorderedButton>
            <DownloadFilledButton
              fileName={productData.binaryFileName!}
              label={`Download ${productData.name}`}></DownloadFilledButton>
          </div>
        </div>
      </BorderedContainer>
    </>
  );
}
