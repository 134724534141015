import React, { useEffect, useMemo, useState } from 'react';
import { HeadMetadata } from '../components/HeadMetadata';
import { Header, HeaderWrapper } from '../components/Header';
import { DataLoader, LoadingStatus } from '../types/DataLoader';
import { get } from '../libs/Requests';
import QuillEditor from 'react-quill';
import { useNavigate } from 'react-router-dom';

interface DocumentDTO {
  documentId: string;
  documentTitle: string;
  documentContent: string;
}

export interface DocumentDataLoader extends DataLoader {
  data?: DocumentDTO;
}

export function Document() {
  const navigate = useNavigate();
  const documentId = location.pathname.split('/document/').pop()?.replaceAll('/', '');

  const [documentDataLoader, setDocumentDataLoader] = useState<DocumentDataLoader>({
    loadingStatus: LoadingStatus.Loading,
    data: {
      documentId: '',
      documentTitle: '',
      documentContent: ''
    }
  });

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    setDocumentDataLoader({
      ...documentDataLoader,
      loadingStatus: LoadingStatus.Loading
    });

    get(`/document/${documentId}`, { signal })
      .then(async (documentResponse) => {
        const data = (await documentResponse.json()) as DocumentDTO;

        setDocumentDataLoader({
          ...documentDataLoader,
          data: data,
          loadingStatus: LoadingStatus.Succeed
        });
      })
      .catch(() => {
        navigate('/not_found');
      });
  }, []);

  const modules = useMemo(
    () => ({
      toolbar: false
    }),
    []
  );

  return (
    <>
      <HeadMetadata
        title={`Mint Medical Subscription Store | ${documentDataLoader.data?.documentTitle || 'Document Title'}`}></HeadMetadata>

      <HeaderWrapper>
        <Header>{documentDataLoader.data?.documentTitle || 'Document Title'}</Header>
      </HeaderWrapper>

      <QuillEditor
        className="h-full w-full border-none"
        theme="snow"
        readOnly={true}
        modules={modules}
        value={documentDataLoader.data!.documentContent!}
      />
    </>
  );
}
