import React, { useState } from 'react';
import { put } from '../../../../libs/Requests';
import { ApproveButton } from '../../../../components/Buttons/ApproveButton';
import { DenyButton } from '../../../../components/Buttons/DenyButton';
import { ChildrenOwnerProps } from '../../../../components/Base';
import { ConfirmationActions, ConfirmationModal } from './ConfirmationModal';

enum CompanyStatus {
  Approved = 'Approved',
  Denied = 'Denied'
}

interface CompanyStatusUpdaterProps extends ChildrenOwnerProps {
  companyName: string;
  companyId: string;
  onStatusChange: (newStatus: string) => void;
}

interface ConfirmationData {
  action: string;
  yesLabel: string;
  yesAction: () => void;
}

export function CompanyActionUpdater(props: CompanyStatusUpdaterProps) {
  const [confirmationData, setConfirmationData] = useState<ConfirmationData | undefined>();

  async function handleStatusClick(status: string) {
    try {
      await put(
        `/admin/company/${props.companyId}/status`,
        JSON.stringify({
          status: status
        })
      );

      props.onStatusChange(status);
    } catch (e) {
      console.error(e);
    }
  }

  function fireConfirmationModal(action: CompanyStatus) {
    setConfirmationData({
      action: action,
      yesLabel: action === CompanyStatus.Approved ? 'Yes, Approve' : 'Yes, Deny',
      yesAction: async () => {
        await handleStatusClick(action);
      }
    });
  }

  return (
    <div className="flex flex-row justify-end">
      {confirmationData && (
        <ConfirmationModal
          action={
            confirmationData.action === CompanyStatus.Approved ? ConfirmationActions.Approve : ConfirmationActions.Deny
          }
          title={'Confirmation'}
          visible={!!confirmationData}
          yesLabel={confirmationData.yesLabel}
          closeModal={() => {
            setConfirmationData(undefined);
          }}
          yesMethod={confirmationData.yesAction}>
          Are you sure you want to {confirmationData.action === CompanyStatus.Approved ? 'approve' : 'deny'} company{' '}
          <span className=" font-semibold">{props.companyName}</span>?
        </ConfirmationModal>
      )}
      <div className="flex flex-col space-x-4 self-end md:flex-row">
        {props.children}
        <ApproveButton
          onClick={() => {
            fireConfirmationModal(CompanyStatus.Approved);
          }}></ApproveButton>
        <DenyButton
          onClick={() => {
            fireConfirmationModal(CompanyStatus.Denied);
          }}></DenyButton>
      </div>
    </div>
  );
}
