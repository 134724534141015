import React, { useState } from 'react';
import { Modal } from '../../../../components/Modal/Modal';
import { Input } from '../../../../components/Input';
import { post } from '../../../../libs/Requests';
import { DataSender, SendingStatus } from '../../../../types/DataLoader';
import { FilledButton } from '../../../../components/Buttons';
import { ScaleLoader } from 'react-spinners';

export interface CompanyLicenseUserFormProps {
  onFormSubmit?: () => void;
  onCloseClick: () => void;
  licenseId: string;
}

interface CompanyLicenseUserDTO {
  first_name: string;
  last_name: string;
  phone_number: string;
  email: string;
  department_position: string;
  license_id: string;
}

export function CompanyLicenseUserForm(props: CompanyLicenseUserFormProps) {
  const [dateSender, setDataSender] = useState<DataSender>({ sendingStatus: SendingStatus.Idle });
  const [submissionError, setSubmissionError] = useState('');
  const [companyLicenseUserFields, setCompanyLicenseUserFields] = useState<CompanyLicenseUserDTO>({
    first_name: '',
    last_name: '',
    phone_number: '',
    department_position: '',
    email: '',
    license_id: props.licenseId
  });

  function handleChange(name: string, value: string | number) {
    setCompanyLicenseUserFields({ ...companyLicenseUserFields, [name]: value });
  }
  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setSubmissionError('');

    try {
      companyLicenseUserFields.phone_number = companyLicenseUserFields.phone_number.replaceAll('-', '');

      setDataSender({ sendingStatus: SendingStatus.Sending });

      const response = await post('/company/license_users', JSON.stringify([companyLicenseUserFields]));

      if (response.status === 400) {
        setSubmissionError(`${(await response.json()).error}`);

        setDataSender({ sendingStatus: SendingStatus.Idle });
        return;
      }

      setDataSender({ sendingStatus: SendingStatus.Idle });
      props.onFormSubmit && props.onFormSubmit();
      props.onCloseClick();
    } catch (error) {
      const errorText = 'Some error occurred during submission :( Try again.';

      setSubmissionError(errorText);
      console.error(error);

      setDataSender({ sendingStatus: SendingStatus.Idle });
    }
  }

  return (
    <>
      <Modal
        closeModal={props.onCloseClick}
        visible={true}>
        <div className="h-full w-full space-y-8">
          <div className="text-center">
            <div className="text-lg font-bold">Invite Team Member</div>
          </div>

          <form
            className="h-full w-full"
            onSubmit={handleSubmit}>
            <div className="flex flex-col md:flex-row md:space-x-4">
              <Input
                id="first_name"
                name="first_name"
                label="First Name"
                required
                onChange={(event) => {
                  handleChange(event.target.name, event.target.value);
                }}
                type="text"></Input>
              <Input
                id="last_name"
                name="last_name"
                label="Last Name"
                required
                onChange={(event) => {
                  handleChange(event.target.name, event.target.value);
                }}
                type="text"></Input>
            </div>
            <div>
              <Input
                id="email"
                name="email"
                label="Company Email Address"
                required
                onChange={(event) => {
                  handleChange(event.target.name, event.target.value);
                }}
                type="email"></Input>
            </div>
            <div className="flex flex-col md:flex-row md:space-x-4">
              <Input
                id="department_position"
                name="department_position"
                label="Department / Position"
                required
                onChange={(event) => {
                  handleChange(event.target.name, event.target.value);
                }}
                type="text"></Input>
              <Input
                id="phone_number"
                name="phone_number"
                label="Business Phone Number"
                required
                onChange={(event) => {
                  handleChange(event.target.name, event.target.value);
                }}
                value={companyLicenseUserFields.phone_number}
                type="text"></Input>
            </div>

            <div className="flex min-h-8 w-full">
              <span className={submissionError ? 'visible mx-auto self-center text-center text-red-500' : ' invisible'}>
                {submissionError}
              </span>
            </div>

            <div className="flex py-2">
              <FilledButton
                type="submit"
                disabled={dateSender.sendingStatus != SendingStatus.Idle}>
                <div className="flex w-full flex-row">
                  {dateSender.sendingStatus != SendingStatus.Idle && (
                    <ScaleLoader
                      className="mx-auto"
                      color="#fff"></ScaleLoader>
                  )}
                  {dateSender.sendingStatus == SendingStatus.Idle && (
                    <span className="mx-auto text-center">Submit</span>
                  )}
                </div>
              </FilledButton>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
}
