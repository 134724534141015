import React from 'react';
import { HeadMetadata } from '../components/HeadMetadata';
import { Header, HeaderWrapper } from '../components/Header';
import { Tiers } from '../features/tiers';

const SEOMetadata = {
  Title: 'Mint Medical Subscription Store | mint REX™',
  Description:
    'mint REX™ - Vestibulum congue lacus at erat luctus condimentum. Suspendisse sit amet felis ac eros aliquam bibendum efficitur ac est.'
};

export function Landing() {
  return (
    <>
      <HeadMetadata
        title={SEOMetadata.Title}
        description={SEOMetadata.Description}></HeadMetadata>

      <HeaderWrapper>
        <Header>Choose Your Plan</Header>
      </HeaderWrapper>

      <Tiers></Tiers>
    </>
  );
}
