import React from 'react';

export interface LocalizedPriceProps {
  valueInCents: number;
  currency: string;
}

export function LocalizedPrice(props: LocalizedPriceProps) {
  return (
    <>
      {props.currency === 'eur' ? '€' : ''}
      {(props.valueInCents / 100).toFixed(2).toLocaleString()}
    </>
  );
}
