export enum LoadingStatus {
  Succeed = 'Succeed',
  Failed = 'Failed',
  Loading = 'Loading'
}

export enum SendingStatus {
  Succeed = 'Succeed',
  Failed = 'Failed',
  Sending = 'Sending',
  Idle = 'Idle'
}

export interface DataLoader {
  loadingStatus: LoadingStatus;
  error?: string;
  data?: unknown;
}

export interface DataSender {
  sendingStatus: SendingStatus;
  error?: string;
  data?: unknown;
}
