import React from 'react';
import { Helmet } from 'react-helmet-async';

interface HeadMetadataProps {
  title?: string;
  description?: string;
  image?: string;
}

export function HeadMetadata(props: HeadMetadataProps) {
  return (
    <Helmet>
      {props.title && <title>{props.title}</title>}
      {props.title && (
        <meta
          name="twitter:title"
          content={props.title}
        />
      )}
      {props.title && (
        <meta
          property="og:title"
          content={props.title}
        />
      )}

      {props.description && (
        <meta
          name="description"
          content={props.description}
        />
      )}
      {props.description && (
        <meta
          name="twitter:description"
          content={props.description}
        />
      )}
      {props.description && (
        <meta
          property="og:description"
          content={props.description}
        />
      )}

      {props.image && (
        <meta
          name="twitter:image"
          content={props.image}
        />
      )}
      {props.image && (
        <meta
          property="og:image"
          content={props.image}
        />
      )}

      <meta
        property="og:type"
        content="website"
      />
    </Helmet>
  );
}
