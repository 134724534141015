import React from 'react';
import { BorderedButton } from '../../components/Buttons';
import { handleDownload } from './handleDownload';

interface DownloadBorderedButtonProps {
  fileName: string;
  label: string;
}

export function DownloadBorderedButton(props: DownloadBorderedButtonProps) {
  const downloadRef: React.RefObject<HTMLAnchorElement> = React.createRef();

  return (
    <>
      <a
        ref={downloadRef}
        className="hidden"></a>

      <BorderedButton
        onClick={async () => {
          await handleDownload(downloadRef, props.fileName);
        }}>
        <div className="flex">
          <div className="mx-auto flex flex-row">
            <svg
              className="h-8 w-8 fill-mint-medical-green p-1"
              viewBox="0 0 21 27">
              <path d="M0 0V27H21V7.88494L12.9313 0H0ZM12.8333 1.17391L19.8333 8.21739H12.8333V1.17391ZM9.78223 10.5308C10.1354 10.5308 10.4453 10.6799 10.6549 10.9527C11.0993 11.5351 11.0697 12.7227 10.5638 14.4859C11.1152 15.4282 11.8102 16.2835 12.5212 16.891C13.2959 16.7306 13.9613 16.6503 14.499 16.6503C16.126 16.6503 16.3037 17.4688 16.3037 17.8219C16.3014 18.9546 15.0983 18.9546 14.6449 18.9546C13.8678 18.9546 13.0589 18.6542 12.2409 18.0581C11.0492 18.3424 9.71842 18.778 8.53125 19.2756C7.02051 21.8825 6.09993 21.8825 5.7946 21.8825C5.60775 21.8825 5.42773 21.8389 5.26139 21.7518C4.73275 21.4744 4.66667 21.0685 4.67578 20.8553C4.68717 20.5802 4.71908 19.757 7.74512 18.4341C8.37858 17.2785 8.97786 15.8799 9.37663 14.6097C8.91634 13.7407 8.16211 12.0349 8.74088 11.0811C8.95736 10.728 9.3265 10.5308 9.78223 10.5308ZM9.76628 11.5947C9.72982 11.6107 9.70475 11.6199 9.69792 11.6222C9.61133 11.7002 9.58626 12.1243 9.80729 12.8305C9.92806 12.0211 9.83463 11.6658 9.76628 11.5947ZM10.1514 15.8685L10.1263 15.9579L10.0967 15.9097C9.86654 16.5288 9.60221 17.157 9.32194 17.7577L9.41309 17.7188L9.37207 17.7898C9.96908 17.5766 10.5775 17.384 11.1676 17.2212L11.138 17.196L11.2314 17.1754C10.8532 16.7856 10.4863 16.3431 10.1514 15.8685ZM14.499 17.7279C14.3372 17.7279 14.1595 17.7394 13.9635 17.7554C14.2005 17.8334 14.4284 17.8747 14.6449 17.8747C14.9342 17.8747 15.0869 17.8517 15.1667 17.8311C15.0983 17.7898 14.9092 17.7279 14.499 17.7279ZM6.4554 20.3234C6.15462 20.5183 5.97233 20.6765 5.87207 20.7842C5.98828 20.743 6.18652 20.6123 6.4554 20.3234Z" />
            </svg>

            <span className="my-auto">{props.label}</span>
          </div>
        </div>
      </BorderedButton>
    </>
  );
}
