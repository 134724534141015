import React, { useState } from 'react';
import { Input } from '../../../../components/Input';
import { ChildrenOwnerProps } from '../../../../components/Base';

interface CompanyDataFormProps extends ChildrenOwnerProps {
  onFormFieldChange: (name: string, value: string) => void;
  onSubmit: (formData: CompanyDataDTO) => void;
  submissionError: unknown;
  submitLabel?: string;
  hideCompanyFields?: boolean;
}

export interface CompanyDataDTO {
  email: string;
  first_name: string;
  last_name: string;
  business_name: string;
  phone_number: string;
  password: string;
  domains: string;
}

export interface CompanySignUp extends CompanyDataDTO {
  reenteredPassword: string;
}

export function CompanyDataForm(props: CompanyDataFormProps) {
  const [formError, setFormError] = useState('');

  const [companyFormFields, setCompanyFormFields] = useState<CompanySignUp>({
    email: '',
    first_name: '',
    last_name: '',
    business_name: '',
    phone_number: '',
    password: '',
    reenteredPassword: '',
    domains: ''
  });

  function handleChange(name: string, value: string) {
    setCompanyFormFields({ ...companyFormFields, [name]: value });

    props.onFormFieldChange(name, value);
  }

  async function handleSubmit(event: React.SyntheticEvent<HTMLFormElement>) {
    event.preventDefault();
    setFormError('');

    if (companyFormFields.password != companyFormFields.reenteredPassword) {
      setFormError('Password and reentered password do not match.');
      return;
    }

    props.onSubmit(companyFormFields);
  }

  return (
    <form onSubmit={handleSubmit}>
      {!props.hideCompanyFields ? (
        <>
          <div className="w-full md:w-1/2 md:pr-2">
            <Input
              id="emailAddress"
              label="Email Address"
              required
              onChange={(event) => {
                handleChange('email', event.target.value);
              }}
              type="email"></Input>
          </div>
          <div className="py-4 text-base font-bold">Company Contact</div>
          <div className="flex flex-col md:flex-row md:space-x-4">
            <Input
              id="firstName"
              label="First Name"
              required
              onChange={(event) => {
                handleChange('first_name', event.target.value);
              }}
              type="text"></Input>
            <Input
              id="lastName"
              required
              onChange={(event) => {
                handleChange('last_name', event.target.value);
              }}
              label="Last Name"
              type="text"></Input>
          </div>
          <div className="flex flex-col md:flex-row md:space-x-4">
            <Input
              id="businessName"
              required
              onChange={(event) => {
                handleChange('business_name', event.target.value);
              }}
              label="Business Name"
              type="text"></Input>
            <Input
              id="businessPhoneNumber"
              onChange={(event) => {
                handleChange('phone_number', event.target.value);
              }}
              required
              label="Business Phone Number"
              type="tel"></Input>
          </div>
          <div className="flex flex-col md:flex-row md:space-x-4">
            <Input
              id="password"
              label="Password"
              required
              onChange={(event) => {
                handleChange('password', event.target.value);
              }}
              autoComplete="new-password"
              type="password"></Input>
            <Input
              id="reenteredPassword"
              label="Re-Enter Password"
              onChange={(event) => {
                handleChange('reenteredPassword', event.target.value);
              }}
              required
              autoComplete="new-password"
              type="password"></Input>
          </div>
          <div className="flex flex-col p-2 text-xs md:flex-row md:space-x-4">
            <div className="flex w-full flex-col">
              <span
                className={
                  companyFormFields.password.length > 0
                    ? companyFormFields.password.length >= 8
                      ? 'text-mint-medical-green'
                      : ' text-red-500'
                    : ' text-mint-medical-grey'
                }>
                Contains at least 8 characters
              </span>
              <span
                className={
                  companyFormFields.password.length > 0
                    ? companyFormFields.password.match(/\d/g)
                      ? 'text-mint-medical-green'
                      : ' text-red-500'
                    : ' text-mint-medical-grey'
                }>
                Contains at least 1 number
              </span>
              <span
                className={
                  companyFormFields.password.length > 0
                    ? companyFormFields.password.match(/[A-Z]/g)
                      ? 'text-mint-medical-green'
                      : ' text-red-500'
                    : ' text-mint-medical-grey'
                }>
                Contains at least 1 uppercase letter
              </span>
              <span
                className={
                  companyFormFields.password.length > 0
                    ? companyFormFields.password.match(/[a-z]/g)
                      ? 'text-mint-medical-green'
                      : ' text-red-500'
                    : ' text-mint-medical-grey'
                }>
                Contains at least 1 lowercase letter
              </span>
            </div>
            <div className="flex w-full flex-col pl-2">
              <span
                className={
                  companyFormFields.password.length > 0 && companyFormFields.reenteredPassword.length > 0
                    ? companyFormFields.password === companyFormFields.reenteredPassword
                      ? 'text-mint-medical-green'
                      : ' text-red-500'
                    : ' text-mint-medical-grey'
                }>
                Passwords must match
              </span>
            </div>
          </div>
          <div className="py-4 text-base font-bold">
            List all domains used by your org for email in a comma separated list
          </div>
          <div>
            <Input
              id="domains"
              label=""
              required
              onChange={(event) => {
                handleChange('domains', event.target.value);
              }}
              placeholder="mint.com, mint.org"
              type="text"></Input>
          </div>
        </>
      ) : null}
      {props.children}

      <div className="flex min-h-8 w-full">
        <span
          className={
            props.submissionError || formError ? 'visible mx-auto self-center text-center text-red-500' : ' invisible'
          }>
          {(props.submissionError as string) || formError}
        </span>
      </div>

      <div className="flex py-2">
        <div className="mx-auto w-full md:max-w-md">
          <Input
            type="submit"
            value={props.submitLabel ? props.submitLabel : 'Start subscription'}></Input>
        </div>
      </div>
    </form>
  );
}
