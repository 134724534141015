import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import { Header, HeaderWrapper } from '../components/Header';
import { SignIn } from '../features/auth/components/SignIn/SignIn';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { HeadMetadata } from '../components/HeadMetadata';

export function SignInPage() {
  const location = useLocation();
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const { user } = useAuthenticator((context) => {
    return [context.user];
  });

  function handleOnSignIn() {
    setUserLoggedIn(true);
  }

  if (userLoggedIn || user) {
    return (
      <Navigate
        to={location.state ? location.state.location : '/account'}
        replace={true}
      />
    );
  }

  return (
    <>
      <HeadMetadata title={'Mint Medical Subscription Store | Sign In'}></HeadMetadata>

      <HeaderWrapper>
        <Header>Welcome to the Mint Medical Subscription Store</Header>
      </HeaderWrapper>

      <SignIn onSignIn={handleOnSignIn}></SignIn>
    </>
  );
}
