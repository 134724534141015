import React, { useEffect, useState } from 'react';
import { Header, HeaderWrapper } from '../../components/Header';
import { Input } from '../../components/Input';
import { get, put } from '../../libs/Requests';
import { PasswordEditForm } from '../CompanyManagerAccount/components/CompanyAccountDetails/PasswordEditForm';
import { CompanyMemberLicense } from './CompanyMemberLicense';
import { HeadMetadata } from '../../components/HeadMetadata';

export interface CompanyMemberNotEditableFieldsDTO {
  email: string;
  companyName: string;
}

export interface CompanyMemberUpdateDTO {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  position: string;
}

export interface LicenseDTO {
  product_id: string;
  is_active: boolean;
  license_id: string;
  status: string;
  cancel_at: number;
  start_date: number;
  period_end_date: number;
  period_start_date: number;
}

export function CompanyMember() {
  const [memberNotEditableData, setMemberNotEditableData] = useState<CompanyMemberNotEditableFieldsDTO | undefined>(
    undefined
  );
  const [memberDataUpdate, setMemberDataUpdate] = useState<CompanyMemberUpdateDTO>({
    firstName: '',
    lastName: '',
    position: '',
    phoneNumber: ''
  });
  const [licensesData, setLicensesData] = useState<LicenseDTO[]>();
  const [inEditMode, setInEditMode] = useState(false);

  async function getLicenses() {
    try {
      const licenses = await get('/license');

      setLicensesData(await licenses.json());
    } catch (error) {
      console.error(error);
    }
  }

  async function getMemberData() {
    try {
      const member = await get('/company/member');

      const memberJ = await member.json();

      setMemberNotEditableData({
        email: memberJ.email,
        companyName: memberJ.companyName
      });

      setMemberDataUpdate({
        firstName: memberJ.firstName!,
        lastName: memberJ.lastName!,
        phoneNumber: memberJ.phoneNumber!,
        position: memberJ.position!
      });
    } catch (error) {
      console.error(error);
    }
  }

  function handleChange(fieldName: string, fieldValue: string) {
    setMemberDataUpdate({
      ...memberDataUpdate,
      [fieldName]: fieldValue
    });
  }

  useEffect(() => {
    getMemberData();

    getLicenses();
  }, []);

  async function handleEditClick() {
    if (inEditMode) {
      await updateData();
    }

    setInEditMode(!inEditMode);
  }

  async function updateData() {
    await put(
      '/company/member',
      JSON.stringify({
        ...memberDataUpdate,
        licenseId: licensesData?.at(0)?.license_id
      })
    );
  }

  return (
    <>
      <HeadMetadata title={'Mint Medical Subscription Store | Account'}></HeadMetadata>

      <HeaderWrapper>
        <Header>My Account</Header>
      </HeaderWrapper>

      <hr className="h-px border-0 bg-mint-medical-grey-lighter"></hr>

      <div className="flex w-full flex-col">
        {licensesData?.map((license) => (
          <CompanyMemberLicense
            key={license.license_id}
            license={license}></CompanyMemberLicense>
        ))}

        <div className="flex justify-between py-4">
          <span className="font-semibold">Company contact</span>
        </div>

        <div className="flex flex-col md:flex-row md:space-x-4">
          <Input
            type="email"
            label="Email"
            value={memberNotEditableData?.email}
            disabled={true}></Input>

          <Input
            type="text"
            disabled={true}
            value={memberNotEditableData?.companyName}
            label="Business Name"></Input>
        </div>

        <div className="flex justify-between py-4">
          <span className="font-semibold">My Information</span>
          <input
            className="font-semibold text-mint-medical-green underline"
            type="submit"
            form="accountDetails"
            value={inEditMode ? 'Save' : 'Edit'}></input>
        </div>

        <form
          id="accountDetails"
          onSubmit={(event) => {
            event.preventDefault();

            handleEditClick();
          }}>
          <div className="flex flex-col md:flex-row md:space-x-4">
            <Input
              type="text"
              disabled={!inEditMode}
              required={true}
              name="firstName"
              id="firstName"
              value={memberDataUpdate?.firstName}
              onChange={(event) => {
                handleChange(event.target.name, event.target.value);
              }}
              label="First Name"></Input>
            <Input
              type="text"
              disabled={!inEditMode}
              required
              name="lastName"
              id="lastName"
              value={memberDataUpdate?.lastName}
              onChange={(event) => {
                handleChange(event.target.name, event.target.value);
              }}
              label="Last Name"></Input>
          </div>
          <div className="flex flex-col md:flex-row md:space-x-4">
            <Input
              type="text"
              disabled={!inEditMode}
              required
              name="position"
              id="position"
              value={memberDataUpdate?.position}
              onChange={(event) => {
                handleChange(event.target.name, event.target.value);
              }}
              label="Department/Position"></Input>
            <Input
              label="Business Phone Number"
              disabled={!inEditMode}
              required
              name="phoneNumber"
              id="phoneNumber"
              value={memberDataUpdate?.phoneNumber}
              onChange={(event) => {
                handleChange(event.target.name, event.target.value);
              }}
              type="tel"></Input>
          </div>
        </form>

        <PasswordEditForm></PasswordEditForm>
      </div>
    </>
  );
}

export interface ExpirationMessageProps {
  endDateTimestamp: number;
}

export function ExpirationMessage(props: ExpirationMessageProps) {
  function generatePeriodMessage() {
    let _ = props.endDateTimestamp > Date.now() / 100 ? 'Expires in ' : 'Expired on ';

    const hoursDiff = 60 * 60;
    const daysDiff = hoursDiff * 24;

    if ((props.endDateTimestamp - Date.now() / 1000) / daysDiff >= 1) {
      _ += `${Math.floor((props.endDateTimestamp - Date.now() / 1000) / daysDiff)} days`;
    } else if ((props.endDateTimestamp - Date.now() / 1000) / hoursDiff >= 2) {
      _ += `${Math.floor((props.endDateTimestamp - Date.now() / 1000) / hoursDiff)} hours`;
    }

    return _;
  }

  return (
    <>
      <span className=" font-semibold text-red-500">{generatePeriodMessage()}</span>
      <span className="my-auto font-semibold text-red-500">
        on {new Date(props.endDateTimestamp * 1000).toLocaleDateString()}
      </span>
    </>
  );
}

export interface RenewingAtMessageProps {
  renewingTimestamp: number;
}

export function RenewingAtMessage(props: RenewingAtMessageProps) {
  function generatePeriodMessage() {
    let _ = 'Renews in ';

    const hoursDiff = 60 * 60;
    const daysDiff = hoursDiff * 24;

    if ((props.renewingTimestamp - Date.now() / 1000) / daysDiff >= 1) {
      _ += `${Math.floor((props.renewingTimestamp - Date.now() / 1000) / daysDiff)} days`;
    } else if ((props.renewingTimestamp - Date.now() / 1000) / hoursDiff >= 2) {
      _ += `${Math.floor((props.renewingTimestamp - Date.now() / 1000) / hoursDiff)} hours`;
    }

    return _;
  }

  return (
    <>
      <span className=" font-semibold text-red-500">{generatePeriodMessage()}</span>
      <span className="my-auto font-semibold text-red-500">
        on {new Date(props.renewingTimestamp * 1000).toLocaleDateString()}
      </span>
    </>
  );
}
