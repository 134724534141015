import React from 'react';
import { SimpleDocuments } from './SimpleDocuments';

export function Config() {
  return (
    <div className="flex flex-col">
      <SimpleDocuments></SimpleDocuments>
    </div>
  );
}
