import React from 'react';
import { ChildrenOwnerProps } from '../Base';

interface ModalProps extends ChildrenOwnerProps {
  closeModal: () => void;
  closeButtonTextColor?: string;
  visible: boolean;
}

export function Modal(props: ModalProps) {
  if (!props.visible) return <></>;

  return (
    <>
      <div className="fixed inset-0 z-50 flex h-screen w-screen items-center justify-center overflow-x-hidden bg-black bg-opacity-50 outline-none focus:outline-none">
        <div className="relative mx-auto my-auto max-w-3xl overflow-y-auto align-middle md:h-fit md:w-fit">
          <div className="relative flex h-full w-full flex-col overflow-y-auto border-0 bg-white p-4 shadow-lg outline-none focus:outline-none md:rounded-lg">
            <div className="self-end">
              <button
                onClick={props.closeModal}
                className={` font-semibold ${props.closeButtonTextColor ? props.closeButtonTextColor : 'text-mint-medical-green'} hover:underline`}>
                Close
              </button>
            </div>
            {props.children}
          </div>
        </div>
      </div>
    </>
  );
}

export function FullScreenModal(props: ModalProps) {
  if (!props.visible) return <></>;

  return (
    <>
      <div className="fixed inset-0 z-50 flex h-screen w-screen items-center justify-center overflow-x-hidden bg-black bg-opacity-50 outline-none focus:outline-none">
        <div className="relative mx-auto my-auto max-w-3xl overflow-y-auto align-middle md:h-fit md:w-full">
          <div className="relative flex h-full w-full flex-col overflow-y-auto border-0 bg-white p-4 shadow-lg outline-none focus:outline-none md:rounded-lg">
            <div className="self-end">
              <button
                onClick={props.closeModal}
                className={` font-semibold ${props.closeButtonTextColor ? props.closeButtonTextColor : 'text-mint-medical-green'} hover:underline`}>
                Close
              </button>
            </div>
            {props.children}
          </div>
        </div>
      </div>
    </>
  );
}
