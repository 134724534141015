import React, { useState } from 'react';
import { Input } from '../../../../components/Input';

interface SignInConfirmationWithNewPasswordFormProps {
  submissionError: unknown;
  onSubmit: (formData: SignInConfirmationWithNewPasswordDTO) => Promise<void>;
}

export interface SignInConfirmationWithNewPasswordDTO {
  password: string;
}

interface FormFields {
  password: string;
  passwordConfirmation: string;
}

export function SignInConfirmationWithNewPasswordForm(props: SignInConfirmationWithNewPasswordFormProps) {
  const [formError, setFormError] = useState('');
  const [isDuringSubmission, setIsDuringSubmission] = useState<boolean>(false);
  const [userFormFields, setUserFormFields] = useState<FormFields>({
    password: '',
    passwordConfirmation: ''
  });

  function handleChange(name: string, value: string) {
    setUserFormFields({ ...userFormFields, [name]: value });

    setFormError('');
  }

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setFormError('');

    if (userFormFields.password != userFormFields.passwordConfirmation) {
      setFormError('Passwords do not match.');
      return;
    }

    setIsDuringSubmission(true);

    props.onSubmit({ password: userFormFields.password }).finally(() => {
      setIsDuringSubmission(false);
    });
  }

  return (
    <form
      className="w-full"
      id="newPasswordForm"
      onSubmit={handleSubmit}>
      <Input
        autoComplete="password"
        id="password"
        name="password"
        type="password"
        label="Enter Password"
        value={userFormFields.password}
        onChange={(event) => {
          handleChange(event.target.name, event.target.value);
        }}
        required={true}></Input>

      <div className="flex w-full flex-col p-2 text-xs">
        <span
          className={
            userFormFields.password.length > 0
              ? userFormFields.password.length >= 8
                ? 'text-mint-medical-green'
                : ' text-red-500'
              : ' text-mint-medical-grey'
          }>
          Contains at least 8 characters
        </span>
        <span
          className={
            userFormFields.password.length > 0
              ? userFormFields.password.match(/\d/g)
                ? 'text-mint-medical-green'
                : ' text-red-500'
              : ' text-mint-medical-grey'
          }>
          Contains at least 1 number
        </span>
        <span
          className={
            userFormFields.password.length > 0
              ? userFormFields.password.match(/[A-Z]/g)
                ? 'text-mint-medical-green'
                : ' text-red-500'
              : ' text-mint-medical-grey'
          }>
          Contains at least 1 uppercase letter
        </span>
        <span
          className={
            userFormFields.password.length > 0
              ? userFormFields.password.match(/[a-z]/g)
                ? 'text-mint-medical-green'
                : ' text-red-500'
              : ' text-mint-medical-grey'
          }>
          Contains at least 1 lowercase letter
        </span>
      </div>

      <Input
        autoComplete="password"
        id="passwordConfirmation"
        name="passwordConfirmation"
        type="password"
        label="Re-enter Password"
        value={userFormFields.passwordConfirmation}
        onChange={(event) => {
          handleChange(event.target.name, event.target.value);
        }}
        required={true}></Input>

      <div className="flex w-full flex-col p-2 text-xs">
        <span
          className={
            userFormFields.password.length > 0 && userFormFields.passwordConfirmation.length > 0
              ? userFormFields.password === userFormFields.passwordConfirmation
                ? 'text-mint-medical-green'
                : ' text-red-500'
              : ' text-mint-medical-grey'
          }>
          Passwords must match
        </span>
      </div>

      <div className="flex min-h-8 w-full">
        <span
          className={
            props.submissionError || formError ? 'visible mx-auto self-center text-center text-red-500' : ' invisible'
          }>
          {(props.submissionError as string) || formError}
        </span>
      </div>

      <div className="mx-auto w-3/6 pt-2">
        <Input
          disabled={isDuringSubmission}
          type="submit"
          value="Submit"></Input>
      </div>
    </form>
  );
}
