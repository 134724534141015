import React from 'react';
import { FilledButton } from '../../components/Buttons';
import { handleDownload } from './handleDownload';

interface DownloadFilledButtonProps {
  fileName: string;
  label: string;
}

export function DownloadFilledButton(props: DownloadFilledButtonProps) {
  const downloadRef: React.RefObject<HTMLAnchorElement> = React.createRef();

  return (
    <>
      <a
        ref={downloadRef}
        className="hidden"></a>

      <FilledButton
        onClick={async () => {
          await handleDownload(downloadRef, props.fileName);
        }}>
        <div className="flex">
          <div className="mx-auto flex flex-row">
            <svg
              className=" my-auto h-8 w-8 fill-white p-1"
              viewBox="0 0 20 20">
              <path d="M8 0V9H4L10 15L16 9H12V0H8ZM0 18V20H20V18H0Z" />
            </svg>
            <span className="my-auto">{props.label}</span>
          </div>
        </div>
      </FilledButton>
    </>
  );
}
