import React from 'react';
import { Navigate, RouteObject, RouterProvider, createBrowserRouter } from 'react-router-dom';

import { Landing } from '../pages/Landing';
import { SignInPage } from '../pages/SignIn';
import { Account } from '../pages/Account';
import { NotFound } from '../pages/NotFound';
import { MainLayout } from '../layout/MainLayout';
import { SignUpPage } from '../pages/SignUp';
import { Company } from '../pages/Company';
import { Document } from '../pages/Document';
import { AuthResetPassword } from '../features/AuthResetPassword/AuthResetPassword';

const AppRoutes = () => {
  const routesForAuthenticatedOnly: RouteObject[] = [
    {
      path: '/',
      element: <MainLayout></MainLayout>,
      children: [
        {
          index: true,
          element: <Landing></Landing>
        },
        {
          path: 'document/*',
          element: <Document></Document>
        },
        {
          path: 'not_found',
          element: <NotFound></NotFound>
        },
        {
          path: 'auth/reset_password',
          element: <AuthResetPassword></AuthResetPassword>
        },
        {
          path: 'auth/sign_in',
          element: <SignInPage></SignInPage>
        },
        {
          path: 'auth/sign_up',
          element: <SignUpPage></SignUpPage>
        },
        {
          path: 'account',
          element: <Account></Account>
        },
        {
          path: 'account/company/*',
          element: <Company></Company>
        },
        {
          path: '*',
          element: <Navigate to="/not_found"></Navigate>
        }
      ]
    }
  ];

  const router = createBrowserRouter([...routesForAuthenticatedOnly]);

  return <RouterProvider router={router} />;
};

export default AppRoutes;
