import React, { useEffect, useState } from 'react';
import { Input } from '../../components/Input';
import { get, put } from '../../libs/Requests';
import { DataLoader, LoadingStatus } from '../../types/DataLoader';
import { InViewSpinner } from '../../components/Spinners/InViewSpinner';

export interface OrganizationEditableDataFormProps {
  dataEndpoint?: string;
  onCompanyNameChange?: (companyName: string) => void;
}

export enum OrganizationEditableDataFormMode {
  EditModeEnabled = 'edit-mode-enabled',
  OrganizationManagerEdited = 'organization-manager-edited',
  OrganizationManagerSaved = 'organization-manager-saved'
}

interface OrganizationEditableDataDTO {
  firstName: string;
  lastName: string;
  companyName: string;
  phoneNumber: string;
}

export interface OrganizationEditableDataDataLoader extends DataLoader {
  data?: OrganizationEditableDataDTO;
}

export function OrganizationEditableDataForm(props: OrganizationEditableDataFormProps) {
  const dataEndpoint = props?.dataEndpoint || '/company/editable_data';

  const [saveOrganizationManagerError, setSaveOrganizationManagerError] = useState('');
  const [mode, setMode] = useState(OrganizationEditableDataFormMode.OrganizationManagerSaved);
  const [dataLoader, setDataLoader] = useState<OrganizationEditableDataDataLoader>({
    loadingStatus: LoadingStatus.Loading,
    data: {
      firstName: '',
      lastName: '',
      companyName: '',
      phoneNumber: ''
    }
  });

  const [data, setData] = useState<OrganizationEditableDataDTO>({
    firstName: '',
    lastName: '',
    companyName: '',
    phoneNumber: ''
  });

  function handleChange(fieldName: string, fieldValue: string) {
    setData({
      ...data,
      [fieldName]: fieldValue
    });

    setMode(OrganizationEditableDataFormMode.OrganizationManagerEdited);

    if (fieldName === 'companyName') {
      props.onCompanyNameChange && props.onCompanyNameChange(fieldValue);
    }
  }

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    get(dataEndpoint, { signal })
      .then(async (organizationEditableDataResponse) => {
        const data = await organizationEditableDataResponse.json();

        setDataLoader({
          ...dataLoader,
          data: data as OrganizationEditableDataDTO,
          loadingStatus: LoadingStatus.Succeed
        });

        setData(data);
      })
      .catch((organizationEditableDataError) => {
        setDataLoader({
          ...dataLoader,
          error:
            organizationEditableDataError.name === 'AbortError'
              ? undefined
              : 'Oops, some problems occurred during loading...',
          loadingStatus: LoadingStatus.Failed
        });
      });

    return () => {
      abortController.abort();
    };
  }, []);

  async function updateData() {
    try {
      await put(dataEndpoint, JSON.stringify(data));
    } catch (error) {
      setSaveOrganizationManagerError(error as string);
      console.error(error);
    }
  }

  async function handleEditClick() {
    if (mode === OrganizationEditableDataFormMode.OrganizationManagerEdited) {
      await updateData();
    }

    setMode(OrganizationEditableDataFormMode.OrganizationManagerSaved);
  }

  return (
    <div>
      <div className="flex justify-between py-4">
        <span className="font-semibold">Company contact</span>
        <button
          disabled={dataLoader.loadingStatus !== LoadingStatus.Succeed}
          className="font-semibold text-mint-medical-green hover:underline"
          onClick={() => {
            if (mode === OrganizationEditableDataFormMode.OrganizationManagerSaved) {
              setMode(OrganizationEditableDataFormMode.EditModeEnabled);
            } else if (mode === OrganizationEditableDataFormMode.EditModeEnabled) {
              setMode(OrganizationEditableDataFormMode.OrganizationManagerSaved);
            } else {
              handleEditClick();
            }
          }}>
          {mode === OrganizationEditableDataFormMode.EditModeEnabled && 'Cancel'}
          {mode === OrganizationEditableDataFormMode.OrganizationManagerEdited && 'Save'}
          {mode === OrganizationEditableDataFormMode.OrganizationManagerSaved && 'Edit'}
        </button>
      </div>

      {saveOrganizationManagerError && <div className="text-center text-red-500">{saveOrganizationManagerError}</div>}

      {dataLoader.loadingStatus != LoadingStatus.Succeed ? (
        <>{dataLoader.error || <InViewSpinner></InViewSpinner>}</>
      ) : null}

      {dataLoader.loadingStatus === LoadingStatus.Succeed && (
        <form
          id="accountDetails"
          onSubmit={(event) => {
            event.preventDefault();

            handleEditClick();
          }}>
          <div className="flex flex-col md:flex-row md:space-x-4">
            <Input
              type="text"
              disabled={mode === OrganizationEditableDataFormMode.OrganizationManagerSaved}
              id="firstName"
              name="firstName"
              required
              onChange={(event) => {
                handleChange(event.target.name, event.target.value);
              }}
              value={data.firstName}
              label="First Name"></Input>
            <Input
              type="text"
              disabled={mode === OrganizationEditableDataFormMode.OrganizationManagerSaved}
              id="lastName"
              required
              name="lastName"
              onChange={(event) => {
                handleChange(event.target.name, event.target.value);
              }}
              value={data.lastName}
              label="Last Name"></Input>
          </div>
          <div className="flex flex-col md:flex-row md:space-x-4">
            <Input
              type="text"
              disabled={mode === OrganizationEditableDataFormMode.OrganizationManagerSaved}
              id="companyName"
              name="companyName"
              required
              onChange={(event) => {
                handleChange(event.target.name, event.target.value);
              }}
              value={data.companyName}
              label="Business Name"></Input>
            <Input
              label="Business Phone Number"
              disabled={mode === OrganizationEditableDataFormMode.OrganizationManagerSaved}
              id="phoneNumber"
              name="phoneNumber"
              required
              onChange={(event) => {
                handleChange(event.target.name, event.target.value);
              }}
              value={data.phoneNumber}
              type="tel"></Input>
          </div>
        </form>
      )}
    </div>
  );
}
