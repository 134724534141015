import React, { useState } from 'react';
import { Modal } from '../../../../components/Modal/Modal';
import { BorderedButton, FilledButton } from '../../../../components/Buttons';
import { ScaleLoader } from 'react-spinners';

export interface DeleteDomainConfirmProps {
  visible: boolean;
  closeModal: () => void;
  domainName: string;
  yesMethod: () => void;
}

export function DeleteDomainConfirm(props: DeleteDomainConfirmProps) {
  const [spinner, setSpinner] = useState(false);

  async function handleYes() {
    setSpinner(true);

    await props.yesMethod();

    setSpinner(false);
  }

  return (
    <>
      <Modal
        closeButtonTextColor="text-red-500"
        closeModal={props.closeModal}
        visible={props.visible}>
        <div className="h-full w-full space-y-8">
          <div className="text-center">
            <div className="text-lg font-bold">Delete Domain</div>
          </div>
          <div className="text-center">
            Are you sure you want to delete <span className=" font-bold">{props.domainName}</span> domain?
          </div>
          <div className="flex flex-row space-x-4">
            <FilledButton
              onClick={handleYes}
              bgColor="bg-red-500">
              <div className="flex w-full flex-row">
                {spinner && (
                  <ScaleLoader
                    className="mx-auto"
                    color="#fff"></ScaleLoader>
                )}
                {!spinner && <span className="mx-auto text-center">Yes, Delete</span>}
              </div>
            </FilledButton>
            <BorderedButton
              onClick={props.closeModal}
              borderColor="border-red-500"
              textColor="text-red-500">
              No
            </BorderedButton>
          </div>
        </div>
      </Modal>
    </>
  );
}
