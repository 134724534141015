import React, { useEffect, useState } from 'react';
import { deleteRequest, get, post } from '../../../../libs/Requests';
import { CompanyLicenseUser } from './CompanyLicenseUser';
import { DeleteCompanyLicenseUserConfirm } from './DeleteCompanyLicenseUserConfirm';
import { BorderedButton } from '../../../../components/Buttons';
import { CompanyLicenseUserForm } from '../CompanyLicenseUserForm/CompanyLicenseUserForm';
import { Link } from 'react-router-dom';
import XLSX from 'xlsx';
import { FullScreenSpinner } from '../../../OrderForm/components/OrderForm/FullScreenSpinner';
import { AddMyselfToggle } from './AddMyselfToggle';
import { useUserData } from '../../../../hooks/useUserData';

export interface CompanyLicenseUsersProps {
  licenseId: string;
  isLicenseActive: boolean;
  onLicensedUsersNumberUpdated: (numberOfLicenseUsers: number) => void;
}

interface LicenseUser {
  firstName: string;
  lastName: string;
  email: string;
  userStatus: string;
}

interface CompanyLicenseUserDTO {
  first_name: string;
  last_name: string;
  phone_number: string;
  email: string;
  department_position: string;
  license_id: string;
}

export function CompanyLicenseUsers(props: CompanyLicenseUsersProps) {
  const { userData } = useUserData();
  const [spinner, setSpinner] = useState(false);
  const [uploadError, setUploadError] = useState('');
  const fileRef: React.RefObject<HTMLInputElement> = React.createRef();
  const fileUploadForm: React.RefObject<HTMLFormElement> = React.createRef();
  const [fetchData, setFetchData] = useState<boolean>(true);
  const [companyLicenseUsers, setCompanyLicenseUsers] = useState<LicenseUser[]>();
  const [error, setError] = useState('');
  const [deleteLicenseUserFormOnScreen, setDeleteLicenseUserFormOnScreen] = useState('');
  const [userInvitationFormOnScreen, setUserInvitationFormOnScreen] = useState(false);

  async function handleLicenseUserRemovedClick(userEmail: string) {
    setDeleteLicenseUserFormOnScreen(userEmail);
  }

  async function handleLicenseUserResendClick(userEmail: string) {
    console.log(userEmail);

    post(`/company/license_users/${userEmail}`, '').then(() => {});
  }

  function handleInviteUserClick() {
    setUserInvitationFormOnScreen(!userInvitationFormOnScreen);
  }

  async function handleFileChange(event: React.ChangeEvent<HTMLInputElement>) {
    const file = event.target.files![0];

    if (!file) return;

    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    reader.onload = (e) => {
      const bstr = e.target!.result;
      const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array' });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const data = XLSX.utils.sheet_to_json(ws, { header: 1 });

      const companyLicenseUsers = [];

      for (let i = 2; i < data.length; i++) {
        const propsArray: string[] = data[i] as string[];

        if (propsArray.length > 0) {
          const usr: CompanyLicenseUserDTO = {
            email: propsArray.at(0)!.trim(),
            first_name: propsArray.at(1)!,
            last_name: propsArray.at(2)!,
            department_position: propsArray.at(3)!,
            phone_number: propsArray.at(4)!.toString().startsWith('+')
              ? propsArray.at(4)!.toString().trim()
              : '+' + propsArray.at(4)!.toString().trim(),
            license_id: props.licenseId
          };

          companyLicenseUsers.push(usr);
        }
      }

      createCompanyLicenseUsers(companyLicenseUsers);
    };
    if (rABS) reader.readAsBinaryString(file);
    else reader.readAsArrayBuffer(file);
  }

  async function createCompanyLicenseUsers(listOfNewUsers: CompanyLicenseUserDTO[]) {
    try {
      setUploadError('');
      setSpinner(true);

      const result = await post('/company/license_users', JSON.stringify(listOfNewUsers));

      if (result.status !== 201) {
        const body = await result.json();
        throw `${body.error}`;
      }

      fileUploadForm.current?.reset();

      setSpinner(false);
      setFetchData(true);
    } catch (error) {
      console.error(error);
      setSpinner(false);

      setUploadError(`${error}`);

      fileUploadForm.current?.reset();
    }
  }

  async function handleFileSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
  }

  async function handleDeletionConfirm() {
    try {
      await deleteRequest(
        `/company/license_user/${deleteLicenseUserFormOnScreen}`,
        JSON.stringify({
          license_id: props.licenseId
        })
      );

      setDeleteLicenseUserFormOnScreen('');

      setFetchData(true);
    } catch (error) {
      console.error(error);
    }
  }

  async function handleAssigningManagerToLicense() {
    setSpinner(true);

    try {
      await post(
        '/company/license_users',
        JSON.stringify([
          {
            first_name: '',
            last_name: '',
            phone_number: '',
            email: userData?.email,
            department_position: 'Manager',
            license_id: props.licenseId
          }
        ])
      );

      setFetchData(true);
    } catch (error) {
      console.error(error);
    }
  }

  async function handleDeletionOfManagerFromLicense() {
    setSpinner(true);

    try {
      await deleteRequest(
        `/company/license_user/${userData?.email}`,
        JSON.stringify({
          license_id: props.licenseId
        })
      );

      setFetchData(true);
    } catch (error) {
      console.error(error);
    }
  }

  function handleInviteMyself(status: boolean) {
    if (status) {
      handleAssigningManagerToLicense();
    } else {
      handleDeletionOfManagerFromLicense();
    }
  }

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    get(`/company/license_users?license_id=${props.licenseId}`, { signal })
      .then(async (companyLicenseUsersResponse) => {
        const _ = await companyLicenseUsersResponse.json();

        setCompanyLicenseUsers(_);

        props.onLicensedUsersNumberUpdated(_.length);

        setSpinner(false);
      })
      .catch((error) => {
        if (error.name === 'AbortError') {
          return;
        }

        setError('Oops, some problems occurred during loading...');

        setSpinner(false);
      });

    setFetchData(false);

    return () => {
      abortController.abort();
    };
  }, [fetchData]);

  if (error) {
    return <>{error}</>;
  }

  if (!companyLicenseUsers) return <></>;

  return (
    <>
      <div className={props.isLicenseActive ? 'flex flex-col gap-4 py-4' : 'hidden'}>
        <span className="text-md font-semibold">How to Upload Multiple Users at Once</span>
        <span className="text-justify">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla sed nibh vitae arcu pellentesque blandit.
          Integer dapibus congue turpis, vulputate scelerisque velit bibendum id. Vivamus vehicula, lacus nec mollis
          dignissim, ligula augue sollicitudin justo, vitae tempor sem ipsum quis risus. Pellentesque porta mauris eget
          libero pharetra tristique.
        </span>
      </div>

      <div className={props.isLicenseActive ? 'mx-auto w-full pt-4 md:w-1/2' : 'hidden'}>
        {spinner && <FullScreenSpinner></FullScreenSpinner>}
        <form
          ref={fileUploadForm}
          id="fileUploadForm"
          onSubmit={handleFileSubmit}>
          {!spinner && (
            <input
              name="file"
              id="file"
              ref={fileRef}
              required
              accept=".xlsx"
              type="file"
              onChange={handleFileChange}
              className="hidden"
            />
          )}

          <BorderedButton
            onClick={() => {
              fileRef.current?.click();
            }}>
            <div className="flex justify-center space-x-2">
              <svg
                className="my-auto"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none">
                <path
                  d="M10 0L4 6H8V15H12V6H16L10 0ZM0 18V20H20V18H0Z"
                  fill="#489E7B"
                />
              </svg>
              <span>Upload Onboarding Spreadsheet</span>
            </div>
          </BorderedButton>
          {uploadError && <div className="text-center text-red-500">{uploadError}</div>}
        </form>
      </div>

      <div className={props.isLicenseActive ? 'flex w-full justify-center space-x-2 pt-4' : 'hidden'}>
        <span className=" font-light">Need a template for onboarding?</span>
        <Link
          target="_blank"
          download={true}
          className=" font-semibold text-mint-medical-green hover:underline"
          to={'/files/mint_medical_license_users.xlsx'}>
          Download Excel Template Here
        </Link>
      </div>

      <div className={props.isLicenseActive ? 'flex flex-col gap-4 py-4' : 'hidden'}>
        <span className="text-md font-semibold">Invite Individual User</span>
        <span className="text-justify">
          Donec id nisl mattis, tempus tellus eget, mattis lacus. Pellentesque non finibus elit. Duis congue, eros
          sollicitudin semper porta, augue augue ultricies quam, eu viverra diam nisl at ligula. Class aptent taciti
          sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Pellentesque habitant morbi tristique
          senectus et netus et malesuada fames ac turpis egestas. Donec vehicula eget risus tincidunt ultrices.
        </span>
      </div>

      <div className={props.isLicenseActive ? 'mx-auto w-full pt-4 md:w-1/4' : 'hidden'}>
        <BorderedButton onClick={handleInviteUserClick}>+ Invite Member</BorderedButton>
      </div>

      <div className={props.isLicenseActive ? 'bg-red my-4 h-px w-full bg-mint-medical-grey-light' : 'hidden'}></div>

      {deleteLicenseUserFormOnScreen && (
        <DeleteCompanyLicenseUserConfirm
          visible={!!deleteLicenseUserFormOnScreen}
          closeModal={() => {
            setDeleteLicenseUserFormOnScreen('');
          }}
          userEmail={deleteLicenseUserFormOnScreen}
          yesMethod={handleDeletionConfirm}></DeleteCompanyLicenseUserConfirm>
      )}

      <div className="flex flex-col gap-4 py-4">
        <div className=" flex w-full justify-between">
          <span className="text-md left-0 font-semibold">Active Users</span>
          <span className="text-md right-0 font-semibold">
            <AddMyselfToggle
              onChange={handleInviteMyself}
              checked={!!companyLicenseUsers.find((item) => item.email === userData?.email)}></AddMyselfToggle>
          </span>
        </div>
        <table>
          <thead>
            <tr>
              <td></td>
              <td></td>
            </tr>
          </thead>
          <tbody>
            {!companyLicenseUsers || !companyLicenseUsers.length ? (
              <tr>
                <td
                  colSpan={2}
                  className="text-center">
                  No Active Users
                </td>
              </tr>
            ) : null}
            {companyLicenseUsers && companyLicenseUsers.length
              ? companyLicenseUsers.map((user) => (
                  <CompanyLicenseUser
                    key={user.email}
                    user={user}
                    onDeleteClicked={handleLicenseUserRemovedClick}
                    onResendClicked={handleLicenseUserResendClick}></CompanyLicenseUser>
                ))
              : null}
          </tbody>
        </table>
      </div>
      {userInvitationFormOnScreen && (
        <CompanyLicenseUserForm
          licenseId={props.licenseId}
          onFormSubmit={() => setFetchData(true)}
          onCloseClick={handleInviteUserClick}></CompanyLicenseUserForm>
      )}
    </>
  );
}
