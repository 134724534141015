import React from 'react';
import { ChildrenOwnerProps } from '../Base';

export function Description(props: ChildrenOwnerProps) {
  return (
    <div className="flex max-h-fit w-full max-w-screen-xl flex-col space-y-4 bg-white py-4 md:py-8">
      <div className="text-justify text-xl text-[#282829] md:text-center md:text-2xl">{props.children}</div>
    </div>
  );
}
