import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ChildrenOwnerProps } from '../Base';

interface NavBarItemProps extends ChildrenOwnerProps {
  link?: string;
  onClick?: () => void;
}

export function NavBarItem(props: NavBarItemProps) {
  const { pathname } = useLocation();
  const isCurrentPath = props.link === pathname;

  const classNames = [
    'block py-2 px-3 rounded hover:underline md:hover:bg-transparent md:border-0 md:p-0',
    isCurrentPath ? 'text-mint-medical-green' : 'text-mint-medical-grey'
  ].join(' ');

  return (
    <li onClick={props.onClick}>
      <Link
        to={props.link!}
        className={classNames}>
        {props.children}
      </Link>
    </li>
  );
}
