import React, { useState } from 'react';
import QRCode from 'react-qr-code';
import { Input } from '../../../../components/Input';

interface TOTPSetupFormProps {
  totpSetupLink: string;
  submissionError: unknown;
  onSubmit: (formData: TOTPCodeDTO) => Promise<void>;
}

export interface TOTPCodeDTO {
  code: string;
}
export function TOTPSetupForm(props: TOTPSetupFormProps) {
  const [isDuringSubmission, setIsDuringSubmission] = useState<boolean>(false);
  const [userFormFields, setUserFormFields] = useState<TOTPCodeDTO>({
    code: ''
  });

  function handleChange(name: string, value: string) {
    setUserFormFields({ ...userFormFields, [name]: value });
  }

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    setIsDuringSubmission(true);

    props.onSubmit(userFormFields).finally(() => {
      setIsDuringSubmission(false);
    });
  }

  return (
    <div className="flex flex-col space-y-2">
      <div className="mx-auto">
        <QRCode value={props.totpSetupLink}></QRCode>
      </div>

      <form
        className="w-full"
        id="totpCodeForm"
        onSubmit={handleSubmit}>
        <Input
          autoComplete="code"
          id="code"
          type="code"
          label="Enter Code"
          value={userFormFields.code}
          onChange={(event) => {
            handleChange('code', event.target.value);
          }}
          required={true}></Input>

        <div className="flex min-h-8 w-full">
          <span
            className={props.submissionError ? 'visible mx-auto self-center text-center text-red-500' : ' invisible'}>
            {props.submissionError as string}
          </span>
        </div>

        <div className="mx-auto w-3/6 pt-2">
          <Input
            disabled={isDuringSubmission}
            type="submit"
            value="Log In"></Input>
        </div>
      </form>
    </div>
  );
}
