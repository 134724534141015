import React from 'react';
import { CompanyOrderedLicenseDTO } from './CompanyOrderedLicenses';
import BorderedContainer from '../../components/Containers/BorderedContainer';
import logo from '../../assets/mint_medical_logo.svg';
import { useProductData } from '../../hooks/useProductData';

export interface CompanyOrderedLicenseProps {
  license: CompanyOrderedLicenseDTO;
}

export function CompanyOrderedLicense(props: CompanyOrderedLicenseProps) {
  const { productData, getTierName, getTierPrice, calculateYearlyPriceForGivenSeats } = useProductData({
    productId: props.license.productId,
    lang: 'en'
  });

  return (
    <BorderedContainer>
      <div className="">
        <div className="flex flex-col justify-between self-start md:flex-row">
          <img
            className="w-3/4 self-center py-4 md:w-1/6 md:self-start"
            src={logo}></img>
          <div className={'my-auto flex w-full flex-row justify-between self-start'}>
            <span className="pl-0 text-base font-semibold text-mint-medical-grey md:pl-4">
              {productData?.name ? productData.name : ''} |{' '}
              {props.license.seats ? getTierName(props.license.seats) : ''}
            </span>
            <span className="text-base font-semibold">
              <>&euro;</>
              {getTierPrice(props.license.seats)} per user/month
            </span>
          </div>
        </div>
        <div className="flex flex-row justify-between self-start">
          <span className=" my-auto flex flex-col text-wrap pr-1 text-base font-semibold text-mint-medical-grey md:flex-row">
            Number of users for your plan
          </span>
          <input
            id="seats"
            disabled={true}
            className="w-12 rounded-md border-2 border-mint-medical-green-lighter p-2 text-center focus:border-mint-medical-green focus:outline-none md:w-24"
            value={props.license.seats}
            type="number"></input>
        </div>
        <div className="">
          <div className="flex justify-between">
            <span className="my-auto text-base font-semibold text-mint-medical-grey">Total</span>
            <span className="text-base font-semibold">
              {calculateYearlyPriceForGivenSeats(props.license.seats)} billed annually
            </span>
          </div>
        </div>
      </div>

      <div className="text-md py-4 text-justify font-semibold text-mint-medical-grey">{productData.description}</div>
    </BorderedContainer>
  );
}
