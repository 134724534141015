import React, { useState } from 'react';
import { Modal } from '../../../../components/Modal/Modal';
import { Input } from '../../../../components/Input';
import { post } from '../../../../libs/Requests';
import { FilledButton } from '../../../../components/Buttons';
import { ScaleLoader } from 'react-spinners';

export interface OrganizationDataDTO {
  email: string;
  first_name: string;
  last_name: string;
  company_name: string;
  phone_number: string;
  domains: string;
}

export interface AddOrganizationFormProps {
  onClose: () => void;
  onAddOrganizationSuccessful: () => void;
}

export function AddOrganizationForm(props: AddOrganizationFormProps) {
  const [formError, setFormError] = useState('');
  const [spinner, setSpinner] = useState(false);

  const [companyFormFields, setCompanyFormFields] = useState<OrganizationDataDTO>({
    email: '',
    first_name: '',
    last_name: '',
    company_name: '',
    phone_number: '',
    domains: ''
  });

  function handleChange(name: string, value: string) {
    setCompanyFormFields({ ...companyFormFields, [name]: value });
  }

  async function handleSubmit(event: React.SyntheticEvent<HTMLFormElement>) {
    event.preventDefault();

    if (spinner) return;

    setFormError('');
    setSpinner(true);

    try {
      const result = await post('/admin/company', JSON.stringify(companyFormFields));

      if (result.status !== 201) {
        const body = await result.json();

        throw `${body.error}`;
      }

      props.onAddOrganizationSuccessful();

      setSpinner(false);
    } catch (error) {
      setFormError(error as string);
      console.error(error);

      setSpinner(false);
    }
  }

  return (
    <Modal
      closeModal={props.onClose}
      visible={true}>
      <div className="text-center">
        <div className="text-lg font-bold">Add Organization</div>
      </div>

      <form onSubmit={handleSubmit}>
        <div className="py-4 text-base font-bold">Company Contact</div>
        <div className="w-full md:w-1/2 md:pr-2">
          <Input
            id="emailAddress"
            label="Email Address"
            required
            onChange={(event) => {
              handleChange('email', event.target.value);
            }}
            type="email"></Input>
        </div>
        <div className="flex flex-col md:flex-row md:space-x-4">
          <Input
            id="firstName"
            label="First Name"
            required
            onChange={(event) => {
              handleChange('first_name', event.target.value);
            }}
            type="text"></Input>
          <Input
            id="lastName"
            required
            onChange={(event) => {
              handleChange('last_name', event.target.value);
            }}
            label="Last Name"
            type="text"></Input>
        </div>
        <div className="flex flex-col md:flex-row md:space-x-4">
          <Input
            id="businessName"
            required
            onChange={(event) => {
              handleChange('company_name', event.target.value);
            }}
            label="Business Name"
            type="text"></Input>
          <Input
            id="businessPhoneNumber"
            onChange={(event) => {
              handleChange('phone_number', event.target.value);
            }}
            required
            label="Business Phone Number"
            type="tel"></Input>
        </div>
        <div className="py-4 text-base font-bold">
          List all domains used by your org for email in a comma separated list
        </div>
        <div>
          <Input
            id="domains"
            label=""
            required
            onChange={(event) => {
              handleChange('company_domains', event.target.value);
            }}
            type="text"></Input>
        </div>
        <div className="flex min-h-8 w-full">
          <span className={formError ? 'visible mx-auto self-center text-center text-red-500' : ' invisible'}>
            {formError}
          </span>
        </div>

        <div className="flex py-2">
          <div className="mx-auto w-full md:max-w-md">
            <FilledButton type="submit">
              {spinner && (
                <ScaleLoader
                  className="mx-auto"
                  color="#fff"></ScaleLoader>
              )}
              {!spinner && 'Submit'}
            </FilledButton>
          </div>
        </div>
      </form>
    </Modal>
  );
}
