import React from 'react';
import { GridLoader } from 'react-spinners';

export function InViewSpinner() {
  return (
    <div className="my-10 flex flex-col">
      <GridLoader
        color="#0AA078"
        className="mx-auto"
      />
    </div>
  );
}
