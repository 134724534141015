import React from 'react';
import { Modal } from '../../../../components/Modal/Modal';
import { BorderedButton, FilledButton } from '../../../../components/Buttons';

export interface DeleteCompanyLicenseUserConfirmProps {
  visible: boolean;
  closeModal: () => void;
  userEmail: string;
  yesMethod: () => void;
}

export function DeleteCompanyLicenseUserConfirm(props: DeleteCompanyLicenseUserConfirmProps) {
  return (
    <>
      <Modal
        closeButtonTextColor="text-red-500"
        closeModal={props.closeModal}
        visible={props.visible}>
        <div className="h-full w-full space-y-8">
          <div className="text-center">
            <div className="text-lg font-bold">Remove Team Member</div>
          </div>
          <div className="text-center">
            Are you sure you want to remove team member with email:{' '}
            <span className=" font-bold">{props.userEmail}</span>?
          </div>
          <div className="flex flex-row space-x-4">
            <FilledButton
              onClick={props.yesMethod}
              bgColor="bg-red-500">
              Yes, Delete
            </FilledButton>
            <BorderedButton
              onClick={props.closeModal}
              borderColor="border-red-500"
              textColor="text-red-500">
              No
            </BorderedButton>
          </div>
        </div>
      </Modal>
    </>
  );
}
