import React, { useEffect, useState } from 'react';
import { get } from '../../../../libs/Requests';
import { CompanyManager } from '../../../../types/CompanyManager';

export interface CompanyManagerInfoProps {
  companyId: string;
}

export function CompanyManagerInfo(props: CompanyManagerInfoProps) {
  const [companyManagerData, setCompanyManagerData] = useState<CompanyManager>();
  const [dataLoaded, setDataLoaded] = useState(false);

  useEffect(() => {
    getCompanyManagerData();
  }, [dataLoaded]);

  async function getCompanyManagerData() {
    if (!dataLoaded) {
      const companyManagerResponse = await get(`/admin/company/${props.companyId}/manager`);

      setCompanyManagerData(await companyManagerResponse.json());
      setDataLoaded(true);
    }
  }

  return (
    <div className={`${!dataLoaded ? 'opacity-0' : 'opacity-100'} transition-opacity duration-300 ease-in`}>
      <div>
        {companyManagerData?.firstName} {companyManagerData?.lastName}
        {!dataLoaded && 'Manager Full Name'}
      </div>
      <div>
        {companyManagerData?.email}
        {!dataLoaded && 'Manager Email'}
      </div>
      <div>
        {companyManagerData?.phoneNumber}
        {!dataLoaded && 'Phone'}
      </div>
    </div>
  );
}
