import React from 'react';

export interface AddMyselfToggleProps {
  checked: boolean;
  onChange?: (status: boolean) => void;
  toggle?: () => void;
}

export function AddMyselfToggle(props: AddMyselfToggleProps) {
  function handleChange() {
    props.onChange && props.onChange!(!props.checked);
  }

  return (
    <label className="inline-flex cursor-pointer items-center">
      <input
        onChange={handleChange}
        type="checkbox"
        value={props.checked === true ? 'checked' : ''}
        checked={props.checked}
        className="peer sr-only"></input>
      <div className="peer relative h-6 w-11 rounded-full bg-gray-200 after:absolute after:start-[2px] after:top-[2px] after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-mint-medical-green peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-mint-medical-green-light rtl:peer-checked:after:-translate-x-full"></div>
      <span className="ms-3 text-sm font-medium text-gray-900">
        {props.checked === false ? 'Include myself as license user' : 'Include myself as license user'}
      </span>
    </label>
  );
}
