import { createContext, useContext } from 'react';

export interface AppConfigData {
  apiUrl: string | null;
  stripeConfiguration: {
    publishable_key: string | null;
  };
  cognitoConfiguration: {
    aws_cognito_region: string | null;
    aws_user_pools_id: string | null;
    aws_user_pools_web_client_id: string | null;
    aws_project_region: string | null;
  };
}

export class AppConfig {
  private static instance: AppConfig;
  private appConfigData: AppConfigData;
  private appConfigDataLoaded: boolean;
  private appConfigLoading: boolean;

  private constructor() {
    this.appConfigDataLoaded = false;
    this.appConfigLoading = false;
    this.appConfigData = {
      apiUrl: '',
      stripeConfiguration: {
        publishable_key: null
      },
      cognitoConfiguration: {
        aws_cognito_region: null,
        aws_user_pools_id: null,
        aws_user_pools_web_client_id: null,
        aws_project_region: null
      }
    };
  }

  public static getInstance(): AppConfig {
    if (!AppConfig.instance) {
      AppConfig.instance = new AppConfig();
    }

    return AppConfig.instance;
  }

  public async getConfig(): Promise<AppConfigData> {
    let configUrl = `https://storeapi.${window.location.hostname}`;
    if (window.location.hostname.indexOf('localhost') != -1 || window.location.hostname.match(/\d/g)) {
      configUrl = 'https://storeapi.mintrex.dev.atmoapps.net';
      // configUrl = 'https://storeapi.storedev.mint-imaging.com';
    }

    if (!this.appConfigDataLoaded) {
      this.appConfigLoading = true;
      this.appConfigData = (await (await fetch(`${configUrl}/config`)).json()) as AppConfigData;

      this.appConfigDataLoaded = true;
      this.appConfigLoading = false;
      this.appConfigData.apiUrl = configUrl;
    }

    return this.appConfigData;
  }
}

interface AppConfigContextValue {
  appConfig: AppConfigData | null;
}

export const AppConfigContext = createContext<AppConfigContextValue | undefined>(undefined);

export const useAppConfigContext = () => {
  const appConfigContext = useContext(AppConfigContext);
  if (appConfigContext === undefined) {
    throw new Error('useAppConfigContext must be inside a AppConfigProvider');
  }
  return appConfigContext;
};
