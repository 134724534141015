import React, { useState } from 'react';
import { post } from '../../libs/Requests';
import { Modal } from '../../components/Modal/Modal';
import { FilledButton } from '../../components/Buttons';
import { ScaleLoader } from 'react-spinners';
import { Input } from '../../components/Input';

interface OrganizationLicenseDTO {
  companyId: string;
  productId: string;
  expirationTimestamp: number;
  seats: number;
  status: 'Ordered' | 'Active' | 'Inactive';
  type: 'AutoRenewal' | 'OneTime';
}

interface AddOrganizationLicenseFormProps {
  companyId: string;
  onClose: () => void;
  onAddOrganizationLicenseSuccessful: () => void;
}

export function AddOrganizationLicenseForm(props: AddOrganizationLicenseFormProps) {
  const [formError, setFormError] = useState('');
  const [spinner, setSpinner] = useState(false);

  const [companyLicenseFormFields, setCompanyLicenseFormFields] = useState<OrganizationLicenseDTO>({
    companyId: props.companyId,
    productId: 'mintrex',
    seats: 0,
    expirationTimestamp: 0,
    status: 'Active',
    type: 'OneTime'
  });

  function handleChange(name: string, value: string | number) {
    setCompanyLicenseFormFields({ ...companyLicenseFormFields, [name]: value });
  }

  async function handleSubmit(event: React.SyntheticEvent<HTMLFormElement>) {
    event.preventDefault();

    if (spinner) return;

    setFormError('');
    setSpinner(true);

    try {
      await post(`/admin/company/${props.companyId}/licenses`, JSON.stringify(companyLicenseFormFields));

      props.onAddOrganizationLicenseSuccessful();
    } catch (error) {
      setFormError(error as string);
      console.error(error);
    }

    setSpinner(false);
  }

  return (
    <Modal
      closeModal={props.onClose}
      visible={true}>
      <div className="text-center">
        <div className="text-lg font-bold">Add Organization License</div>
      </div>

      <form onSubmit={handleSubmit}>
        <div className="py-4 text-base font-bold">License Details</div>
        <div className="flex flex-col">
          <Input
            id="seats"
            min={0}
            label="Licenses Number"
            required
            onChange={(event) => {
              handleChange('seats', event.target.value);
            }}
            type="number"></Input>

          <Input
            id="expirationDate"
            type="date"
            label="License Expiration Date"
            min={new Date().toISOString().slice(0, 10)}
            onChange={(event) => {
              handleChange('expirationTimestamp', new Date(`${event.target.value}T23:59:59`).getTime());
            }}
            required></Input>
        </div>

        <div className="flex min-h-8 w-full">
          <span className={formError ? 'visible mx-auto self-center text-center text-red-500' : ' invisible'}>
            {formError}
          </span>
        </div>

        <div className="flex py-2">
          <div className="mx-auto w-full md:max-w-md">
            <FilledButton type="submit">
              {spinner && (
                <ScaleLoader
                  className="mx-auto"
                  color="#fff"></ScaleLoader>
              )}
              {!spinner && 'Submit'}
            </FilledButton>
          </div>
        </div>
      </form>
    </Modal>
  );
}
