import React from 'react';
import { Main } from '../components/Main/Main';
import { NavBar } from '../components/NavBar';
import { Footer } from '../components/Footer';
import { Outlet } from 'react-router-dom';

export function MainLayout() {
  return (
    <>
      <NavBar></NavBar>
      <Main>
        <Outlet></Outlet>
      </Main>
      <Footer></Footer>
    </>
  );
}
