import React, { useState } from 'react';
import { Header, HeaderWrapper } from '../../../../components/Header';
import { CompaniesList } from '../CompaniesList/CompaniesList';
import {
  Tab,
  TabSwitcher
} from '../../../CompanyManagerAccount/components/CompanyManagerAccount/CompanyManagerAccount';
import { Config } from '../Config/Config';
import { HeadMetadata } from '../../../../components/HeadMetadata';

enum TabsSwitcherState {
  OrganizationDetails,
  Configuration
}

export function MintAdminAccount() {
  const [tabSelected, setTabSelected] = useState(TabsSwitcherState.OrganizationDetails);

  function handleOrganizationDetailsTabClicked() {
    if (tabSelected !== TabsSwitcherState.OrganizationDetails) {
      setTabSelected(TabsSwitcherState.OrganizationDetails);
    }
  }

  function handleConfigurationTabClicked() {
    if (tabSelected !== TabsSwitcherState.Configuration) {
      setTabSelected(TabsSwitcherState.Configuration);
    }
  }

  return (
    <>
      <HeadMetadata title={'Mint Medical Subscription Store | Account'}></HeadMetadata>

      <HeaderWrapper>
        <Header>Administrator Panel</Header>
      </HeaderWrapper>

      <TabSwitcher>
        <Tab
          onClick={handleOrganizationDetailsTabClicked}
          selected={tabSelected === TabsSwitcherState.OrganizationDetails}>
          Organization Details
        </Tab>
        <Tab
          onClick={handleConfigurationTabClicked}
          selected={tabSelected === TabsSwitcherState.Configuration}>
          Configuration
        </Tab>
      </TabSwitcher>

      {tabSelected === TabsSwitcherState.OrganizationDetails && <CompaniesList></CompaniesList>}
      {tabSelected === TabsSwitcherState.Configuration && <Config></Config>}
    </>
  );
}
