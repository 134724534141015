import React from 'react';
import { ChildrenOwnerProps } from '../Base';

export function Main(props: ChildrenOwnerProps) {
  return (
    <main className="flex max-h-full max-h-max w-screen max-w-screen-xl flex-col self-center bg-white p-2">
      {props.children}
    </main>
  );
}
