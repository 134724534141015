import React from 'react';
import { FullScreenModal } from '../../../../components/Modal/Modal';
import Editor from './Editor';

export interface SimpleDocumentEditorProps {
  documentId?: string;
  pathToVisit?: string;
  onClose: () => void;
  onSave?: () => void;
}

export function SimpleDocumentEditor(props: SimpleDocumentEditorProps) {
  return (
    <FullScreenModal
      closeModal={props.onClose}
      visible={true}>
      <div className="w-full md:pr-2">
        <Editor
          onSave={props.onSave}
          documentId={props.documentId}
          pathToVisit={props.pathToVisit}></Editor>
      </div>
    </FullScreenModal>
  );
}
