import React, { useEffect, useState } from 'react';
import { get } from '../../../../libs/Requests';
import { SimpleDocumentEditor } from './SimpleDocumentEditor';
import { Link } from 'react-router-dom';
import { InViewSpinner } from '../../../../components/Spinners/InViewSpinner';

interface SimpleDocument {
  documentId: string;
  documentTitle: string;
  documentContent: string;
}

export function SimpleDocuments() {
  const [simpleDocuments, setSimpleDocuments] = useState<SimpleDocument[]>([]);
  const [documentId, setDocumentId] = useState('');
  const [showAddDocumentForm, setShowAddDocumentForm] = useState<boolean>(false);
  const [fetchData, setFetchData] = useState(true);

  function handleEdit(documentName: string) {
    setDocumentId(documentName);
  }

  function handleAddDocument() {
    setShowAddDocumentForm(true);
  }

  function handleClose() {
    setDocumentId('');
  }

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    get('/admin/document', { signal })
      .then(async (simpleDocumentResponse) => {
        const _: SimpleDocument[] = await simpleDocumentResponse.json();

        setSimpleDocuments(_);
      })
      .catch(() => {});

    setFetchData(false);

    return () => {
      abortController.abort();
    };
  }, [fetchData]);

  return (
    <div className="flex w-full flex-col">
      <div className="flex justify-between py-4">
        <span className="font-semibold">List of documents</span>
        <button
          className="font-semibold text-mint-medical-green hover:underline"
          onClick={handleAddDocument}>
          Add
        </button>
      </div>
      {!simpleDocuments.length ? <InViewSpinner></InViewSpinner> : null}

      <div className="flex w-full flex-col gap-2">
        {simpleDocuments.map((companyLicense) => (
          <div
            key={companyLicense.documentId}
            className="flex w-full flex-row justify-between">
            <div>
              <div className=" font-semibold">{companyLicense.documentTitle}</div>
              <div className="text-base">/document/{companyLicense.documentId}</div>
            </div>
            <div className="my-auto flex space-x-2">
              <Link
                className=" cursor-pointer font-semibold text-mint-medical-green hover:underline"
                to={`/document/${companyLicense.documentId}`}
                target="blank">
                Open in Tab
              </Link>
              <span
                className=" cursor-pointer font-semibold text-mint-medical-green hover:underline"
                onClick={() => handleEdit(companyLicense.documentId)}>
                Edit
              </span>
            </div>
          </div>
        ))}
      </div>

      {documentId && documentId.length && (
        <SimpleDocumentEditor
          documentId={documentId}
          onSave={() => {
            setFetchData(true);
          }}
          onClose={handleClose}
          pathToVisit={`/document/${documentId}`}></SimpleDocumentEditor>
      )}

      {showAddDocumentForm && (
        <SimpleDocumentEditor
          onSave={() => {
            setFetchData(true);
          }}
          onClose={() => {
            setShowAddDocumentForm(false);
          }}></SimpleDocumentEditor>
      )}
    </div>
  );
}
