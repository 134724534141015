import React from 'react';
import { ChildrenOwnerProps, ClickableProps } from '../Base';
import { Button } from './Button';

interface BorderedButtonProps extends ChildrenOwnerProps, ClickableProps {
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset' | undefined;
  borderColor?: string;
  textColor?: string;
}

export function BorderedButton(props: BorderedButtonProps) {
  const borderColor = props.borderColor || 'border-mint-medical-green';
  const textColor = props.textColor || 'text-mint-medical-green';

  return (
    <Button
      className={`mx-auto h-16 w-full rounded-md border-4 ${borderColor} bg-white text-xl font-semibold ${textColor}`}
      disabled={props.disabled}
      type={props.type}
      onClick={props.onClick}>
      {props.children}
    </Button>
  );
}
