import React, { useEffect } from 'react';
import { HeadMetadata } from '../components/HeadMetadata';
import { Header, HeaderWrapper } from '../components/Header';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { OrderForm } from '../features/OrderForm/components/OrderForm/OrderForm';
// import { useAuthenticator } from '@aws-amplify/ui-react';
import { useUserData } from '../hooks/useUserData';

const SEOMetadata = {
  Title: 'mint REX™',
  Description:
    'mint REX™ - Vestibulum congue lacus at erat luctus condimentum. Suspendisse sit amet felis ac eros aliquam bibendum efficitur ac est.'
};

export function SignUpPage() {
  // const { authStatus } = useAuthenticator();
  const { userData } = useUserData();
  const loc = useLocation();
  const navigate = useNavigate();

  const state = loc.state;

  useEffect(() => {
    if (!loc.state) {
      navigate('/');
    }
  }, [state]);

  function handleOnSignUp() {
    navigate('/auth/sign_in');
  }

  if (userData?.groups && userData?.groups?.length > 0) {
    if (userData?.groups.indexOf('ManagersOfCompanies') === -1) {
      return (
        <Navigate
          to="/account"
          replace></Navigate>
      );
    }
  }

  return (
    <>
      <HeadMetadata
        title={SEOMetadata.Title}
        description={SEOMetadata.Description}></HeadMetadata>

      <HeaderWrapper>
        <Header>mint REX™ - Purchase Your Plan</Header>
      </HeaderWrapper>

      <OrderForm
        state={JSON.parse(loc.state)}
        onSignUp={handleOnSignUp}></OrderForm>
    </>
  );
}
