import React from 'react';
import { GridLoader } from 'react-spinners';

export function FullScreenSpinner() {
  return (
    <>
      <div className="fixed inset-0 z-50 flex h-screen w-screen items-center justify-center overflow-x-hidden bg-white bg-opacity-70 outline-none focus:outline-none">
        <div className="relative mx-auto my-auto max-w-3xl overflow-y-auto align-middle md:h-fit md:w-fit">
          <div className="relative flex h-full w-full flex-col overflow-y-auto border-0 bg-transparent p-4 shadow-lg outline-none focus:outline-none md:rounded-lg">
            <div className="my-10 flex flex-col">
              <GridLoader
                color="#489E7B"
                className="mx-auto"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export interface FullScreenMessageProps {
  message: string;
}

export function FullScreenMessage(props: FullScreenMessageProps) {
  return (
    <>
      <div className="fixed inset-0 z-50 flex h-screen w-screen items-center justify-center overflow-x-hidden bg-white bg-opacity-70 outline-none focus:outline-none">
        <div className="relative mx-auto my-auto max-w-3xl overflow-y-auto align-middle md:h-fit md:w-fit">
          <div className="relative flex h-full w-full flex-col overflow-y-auto border-0 bg-transparent p-4 shadow-lg outline-none focus:outline-none md:rounded-lg">
            <div className="my-10 flex flex-col">
              <span className="mx-auto text-xl text-mint-medical-green">{props.message}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
