import React from 'react';
import { ChildrenOwnerProps } from '../Base';

interface AddressProps {
  line1: string;
  line2: string;
  line3: string;
  country: string;
  phoneNumber: string;
  email: string;
}

export function Address(props: AddressProps) {
  return (
    <div className="flex w-full flex-col text-white text-opacity-50">
      <address className="flex flex-col not-italic">
        <span>{props.line1}</span>
        <span>{props.line2}</span>
        <span>{props.line3}</span>
        <span>{props.country}</span>
        <span>{props.phoneNumber}</span>
        <img
          className="mr-2 mt-1 h-4 self-start"
          src={props.email}></img>
      </address>
    </div>
  );
}

export function AddressWrapper(props: ChildrenOwnerProps) {
  return <div className="flex w-fit flex-col text-white text-opacity-50">{props.children}</div>;
}
