import React from 'react';
import { ClickableProps } from '../../../../components/Base';

interface TierProps extends ClickableProps {
  label: string;
  id: string;
  seatsRange: {
    from: number;
    to?: number;
  };
  currency: string;
  priceByMonth?: string;
  tierContent: string[];
  selected?: boolean;
}

export function Tier(props: TierProps) {
  return (
    <div
      className={
        props.selected
          ? 'max-w-1/3 flex min-w-72 flex-col items-center rounded-xl border-4 border-solid border-mint-medical-green bg-mint-medical-green-light p-4 md:p-6'
          : 'max-w-1/3 flex min-w-72 flex-col items-center rounded-xl border-4 border-solid border-mint-medical-green-light bg-mint-medical-green-light p-4 md:p-6'
      }
      onClick={props.onClick}>
      <div className="text-2xl font-semibold text-[#202020]">{props.label}</div>
      <div className="flex">
        {props.priceByMonth ? (
          <>
            <span className="my-auto text-6xl font-semibold text-[#202020]">
              {props.currency === 'eur' && <>&euro;</>}
              {props.priceByMonth.replace('.00', '')}
            </span>
            <span className="text-l my-auto pl-2 font-semibold text-[#202020]">per&nbsp;user /month</span>
          </>
        ) : (
          <span className="my-auto text-6xl font-semibold text-[#202020]">Custom</span>
        )}
      </div>

      <div
        className={
          props.priceByMonth
            ? 'text-l py-2 font-semibold text-mint-medical-green'
            : 'text-l invisible py-2 font-semibold text-mint-medical-green'
        }>
        billed annually
      </div>
      <div>
        <div className="py-2 text-xl font-semibold">What&apos;s Included</div>
        <ul className="px-2">
          {props.tierContent.map((inc, index) => {
            return (
              <li
                className="flex flex-row py-1"
                key={index}>
                <div className="h-8 w-8 self-start">
                  <svg className="h-10 w-10 fill-mint-medical-green">
                    <path d="M15 0C6.71625 0 0 6.71625 0 15C0 23.2838 6.71625 30 15 30C23.2838 30 30 23.2838 30 15C30 6.71625 23.2838 0 15 0ZM23.3838 12.1337L13.9338 21.5837C13.6988 21.8188 13.3812 21.95 13.05 21.95C12.7187 21.95 12.4 21.8188 12.1663 21.5837L7.85 17.2675C7.36125 16.7787 7.36125 15.9887 7.85 15.5C8.33875 15.0112 9.12875 15.0112 9.6175 15.5L13.05 18.9325L21.6162 10.3663C22.105 9.8775 22.895 9.8775 23.3838 10.3663C23.8725 10.855 23.8725 11.645 23.3838 12.1337Z" />
                  </svg>
                </div>

                <span className="pl-1 font-normal leading-7">{inc}</span>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}
