import React from 'react';
import { ChildrenOwnerProps } from '../Base';

export function Header(props: ChildrenOwnerProps) {
  return (
    <h1 className="mx-auto text-center text-xl font-normal text-mint-medical-grey md:text-3xl">{props.children}</h1>
  );
}

export function HeaderWrapper(props: ChildrenOwnerProps) {
  return <div className="mt-20 flex max-h-fit w-full max-w-screen-xl flex-row bg-white md:pt-12">{props.children}</div>;
}
