import React, { useState } from 'react';
import { put } from '../../../../libs/Requests';
import { ConfirmationActions, ConfirmationModal } from './ConfirmationModal';
import { ApproveButton } from '../../../../components/Buttons/ApproveButton';
import { DenyButton } from '../../../../components/Buttons/DenyButton';
import { ChildrenOwnerProps } from '../../../../components/Base';

export interface CompanyDomainStatusUpdaterProps extends ChildrenOwnerProps {
  domainName: string;
  companyId: string;
  onStatusUpdate?: () => void;
}

enum CompanyDomainStatus {
  Approved = 'Approved',
  Denied = 'Denied'
}

interface ConfirmationData {
  action: string;
  yesLabel: string;
  mainColor: string;
  yesAction: () => void;
}

export function CompanyDomainStatusUpdater(props: CompanyDomainStatusUpdaterProps) {
  const [confirmationData, setConfirmationData] = useState<ConfirmationData | undefined>();

  function fireConfirmationModal(action: CompanyDomainStatus) {
    setConfirmationData({
      action: action,
      yesLabel: action === CompanyDomainStatus.Approved ? 'Yes, Approve' : 'Yes, Deny',
      mainColor: action === CompanyDomainStatus.Approved ? 'mint-medical-green' : 'red-500',
      yesAction: async () => {
        await handleStatusClick(action);
      }
    });
  }

  async function handleStatusClick(action: CompanyDomainStatus) {
    try {
      await put(
        `/admin/company/${props.companyId}/domain/${props.domainName}`,
        JSON.stringify({
          status: action
        })
      );

      props.onStatusUpdate && props.onStatusUpdate();
    } catch (e) {
      console.error(e);
    }

    props.onStatusUpdate && props.onStatusUpdate();
  }

  return (
    <div className="flex flex-row justify-end">
      {confirmationData && (
        <ConfirmationModal
          action={
            confirmationData.action === CompanyDomainStatus.Approved
              ? ConfirmationActions.Approve
              : ConfirmationActions.Deny
          }
          title={'Confirmation'}
          visible={!!confirmationData}
          yesLabel={confirmationData.yesLabel}
          mainColor={confirmationData.mainColor}
          closeModal={() => {
            setConfirmationData(undefined);
          }}
          yesMethod={confirmationData.yesAction}>
          Are you sure you want to {confirmationData.action === CompanyDomainStatus.Approved ? 'approve' : 'deny'}{' '}
          domain <span className=" font-semibold">{props.domainName}</span>?
        </ConfirmationModal>
      )}
      <div className="flex flex-col space-x-4 self-end md:flex-row">
        {props.children}
        <ApproveButton
          onClick={() => {
            fireConfirmationModal(CompanyDomainStatus.Approved);
          }}></ApproveButton>
        <DenyButton
          onClick={() => {
            fireConfirmationModal(CompanyDomainStatus.Denied);
          }}></DenyButton>
      </div>
    </div>
  );
}
