import React from 'react';
import { ChildrenOwnerProps } from '../Base';

export interface InputProps extends ChildrenOwnerProps {
  id?: string;
  type?: string;
  autoComplete?: string;
  disabled?: boolean;
  name?: string;
  label?: string;
  pattern?: string;
  placeholder?: string;
  value?: string;
  required?: boolean;
  min?: string | number;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
}

export function Input(props: InputProps) {
  return (
    <div className="w-full">
      {props.label && (
        <label
          className="text-xs font-semibold text-[#787878]"
          htmlFor={props.id}>
          {props.label}
        </label>
      )}
      <input
        id={props.id}
        type={props.type}
        autoComplete={props.autoComplete}
        name={props.name}
        min={props.min}
        required={props.required}
        placeholder={props.placeholder}
        pattern={props.pattern}
        className={
          props.type !== 'submit'
            ? 'h-12 w-full rounded-md border-2 border-mint-medical-grey-light p-4 text-base text-black outline-none focus:border-mint-medical-green focus:outline-2 focus:outline-mint-medical-green-lighter disabled:bg-[#F0F0F0] disabled:text-black'
            : 'mx-auto h-16 w-full cursor-pointer rounded-md bg-mint-medical-green text-xl font-semibold text-white'
        }
        disabled={props.disabled}
        onChange={props.onChange}
        value={props.value}></input>
    </div>
  );
}
