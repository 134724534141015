import React from 'react';
import { ChildrenOwnerProps, ClickableProps } from '../Base';
import { Button } from './Button';

interface FilledButtonProps extends ChildrenOwnerProps, ClickableProps {
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset' | undefined;
  bgColor?: string;
}

export function FilledButton(props: FilledButtonProps) {
  const bgColor = props.bgColor || 'bg-mint-medical-green';

  return (
    <Button
      type={props.type}
      className={
        props.disabled
          ? 'mx-auto h-16 w-full cursor-pointer rounded-md bg-mint-medical-grey-light text-xl font-semibold text-white'
          : `mx-auto h-16 w-full cursor-pointer rounded-md ${bgColor} text-xl font-semibold text-white`
      }
      disabled={props.disabled}
      onClick={props.onClick}>
      {props.children}
    </Button>
  );
}
