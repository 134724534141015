import React, { useEffect, useRef, useState } from 'react';
import { CompanyManagerInfo } from '../CompanyManagerInfo/CompanyManagerInfo';
import { CompanyActionUpdater } from '../CompanyStatusUpdater/CompanyStatusUpdater';
import { get } from '../../../../libs/Requests';
import { CompanyDomainStatusUpdater } from '../CompanyStatusUpdater/CompanyDomainStatusUpdater';
import { DataLoader, LoadingStatus } from '../../../../types/DataLoader';
import { FilledButton } from '../../../../components/Buttons';
import { useLocation, useNavigate } from 'react-router-dom';
import { PendingDot } from '../../../../components/Dots/PendingDot';
import { DeniedDot } from '../../../../components/Dots/DeniedDot';
import { ApprovedDot } from '../../../../components/Dots/ApprovedDot';
import { InViewSpinner } from '../../../../components/Spinners/InViewSpinner';
import { AddOrganizationForm } from '../AddOrganizationForm/AddOrganizationForm';
import { OrganizationXLSXReport } from './OrganizationXLSXReport';

interface Bar {
  status: string;
  domainName: string;
}

interface Foo {
  accountApprovementRequired: boolean;
  domainApprovementRequired: Bar[];
}

interface Company {
  created_at: number;
  status: string;
  requiredAction: Foo;
  id: string;
  name: string;
}

export interface CompanyDomainsDataLoader extends DataLoader {
  data?: Company[];
}

/* eslint-disable @typescript-eslint/no-explicit-any */
function useOutsideAlerter(ref: any, method: () => void) {
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        method();
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
}

export function CompaniesList() {
  const [addOrgOnScreen, setAddOrgOnScreen] = useState(false);
  const location = useLocation();
  const locationHash = location.hash;

  const navigate = useNavigate();
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, () => {
    setFilterFocus(false);
  });
  const [fetchData, setFetchData] = useState<boolean>(true);
  const [queryParams, setQueryParams] = useState<{ searchTerm: string; filter?: string }>({
    searchTerm: '',
    filter: 'All'
  });
  const [searchInput, setSearchInput] = useState<string>('');
  const [dataLoader, setDataLoader] = useState<CompanyDomainsDataLoader>({
    loadingStatus: LoadingStatus.Loading
  });
  const [tabs, setTabs] = useState({
    current: 0,
    max: 0
  });
  const [searchFocus, setSearchFocus] = useState(false);
  const [filterFocus, setFilterFocus] = useState(false);

  function updateCompaniesPage(companyLength: number) {
    const companiesPageFromHash = locationHash.replaceAll('#', '').split('=').pop();
    const tabNum = companyLength > 0 ? 1 : 0;

    setTabs({
      current: companiesPageFromHash ? parseInt(companiesPageFromHash) : tabNum,
      max: Math.ceil(companyLength / 10)
    });

    setCompaniesPageInUrl(companiesPageFromHash ? parseInt(companiesPageFromHash) : tabNum);
  }

  function setCompaniesPageInUrl(pageNumber: number) {
    navigate(`#companiesPage=${pageNumber}`);
  }

  function sortCompanies(companies: Company[]) {
    return companies.sort((a: Company, b: Company) => {
      if (a.created_at > b.created_at) return -1;
      if (a.created_at < b.created_at) return 1;
      return 0;
    });
  }

  function sortCompaniesByKey(key: string) {
    let companies: Company[] = dataLoader.data || [];

    companies = companies.sort((a: Company, b: Company) => {
      if (key === 'status') {
        if (a.status > b.status) return -1;
        if (a.status < b.status) return 1;
        return 0;
      } else {
        if (a.name > b.name) return 1;
        if (a.name < b.name) return -1;
        return 0;
      }
    });

    setDataLoader({
      ...dataLoader,
      data: companies
    });
  }

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    setTabs({
      current: 0,
      max: 0
    });

    const queryParts = [];
    if (queryParams) {
      if (queryParams.searchTerm) {
        queryParts.push(`searchTerm=${encodeURIComponent(queryParams.searchTerm)}`);
      }

      if (queryParams.filter) {
        queryParts.push(`filter=${queryParams.filter}`);
      }
    }

    get(`/admin/companies${queryParts.length ? '?' + queryParts.join('&') : ''}`, { signal })
      .then(async (companyResponse) => {
        const data = sortCompanies((await companyResponse.json()) as Company[]);

        // const companyLength = data.length;
        // const tabNum = companyLength > 0 ? 1 : 0;

        updateCompaniesPage(data.length);

        // setTabs({
        //   current: companiesPageNumber ? parseInt(companiesPageNumber) : tabNum,
        //   max: Math.ceil(companyLength / 10)
        // });

        // navigate(`#tab=${companiesPageNumber ? parseInt(companiesPageNumber) : tabNum}`);

        setDataLoader({
          ...dataLoader,
          data: data,
          loadingStatus: LoadingStatus.Succeed
        });
      })
      .catch((error) => {
        setDataLoader({
          ...dataLoader,
          error: error.name === 'AbortError' ? undefined : 'Oops, some problems occurred during loading...',
          loadingStatus: LoadingStatus.Failed
        });
      });

    setFetchData(false);

    return () => {
      abortController.abort();
    };
  }, [fetchData]);

  function handleCompanyStatusChanged() {
    setFetchData(true);
  }

  function handleSearchFormSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    navigate('#');

    setQueryParams({
      ...queryParams,
      searchTerm: searchInput
    });

    setFetchData(true);
  }

  function handleSearchTextChange(text: string) {
    setSearchInput(text);

    if (!text.length) {
      navigate('#');

      setQueryParams({
        ...queryParams,
        searchTerm: ''
      });

      setFetchData(true);
    }
  }

  function handleFilterChange(value: string) {
    if (queryParams.filter != value) {
      setQueryParams({
        ...queryParams,
        filter: value
      });

      setFilterFocus(!filterFocus);

      setFetchData(true);
    }
  }

  function handleViewDetails(company: any) {
    navigate(`./company/${company.id}`, {
      state: JSON.stringify(company)
    });
  }

  return (
    <div className="flex flex-col">
      <div className="my-auto flex w-full justify-end">
        <div className="w-full md:w-1/4">
          {addOrgOnScreen && (
            <AddOrganizationForm
              onAddOrganizationSuccessful={() => {
                setAddOrgOnScreen(false);
                setFetchData(true);
              }}
              onClose={() => {
                setAddOrgOnScreen(false);
              }}></AddOrganizationForm>
          )}
          <FilledButton
            onClick={() => {
              setAddOrgOnScreen(true);
            }}>
            + Add Organization
          </FilledButton>
        </div>
      </div>

      <div className="w-full py-8">
        <form
          id="searchForm"
          onSubmit={handleSearchFormSubmit}
          className="relative mx-auto md:w-2/3">
          <div className="flex w-full flex-row">
            <div className="flex flex-col self-end">
              <label
                htmlFor="dropdown-button-2"
                className=" pl-2 text-xs font-semibold text-[#787878]">
                Filter
              </label>
              <button
                id="dropdown-button-2"
                data-dropdown-toggle="dropdown-search-city"
                onClick={() => {
                  setFilterFocus(!filterFocus);
                }}
                onFocus={() => {
                  setSearchFocus(true);
                }}
                onBlur={() => {
                  setSearchFocus(false);
                }}
                className={
                  searchFocus
                    ? 'inline-flex h-12 w-12 flex-shrink-0 items-center rounded-s-lg border-2 border-e-0 border-mint-medical-green text-center text-sm font-medium text-gray-500 outline-none'
                    : 'inline-flex h-12 w-12 flex-shrink-0 items-center rounded-s-lg border-2 border-e-0  border-mint-medical-grey-light text-center text-sm font-medium text-gray-500 outline-none'
                }
                type="button">
                <svg
                  className="mx-auto h-6 w-6"
                  width="30"
                  height="30"
                  viewBox="0 0 30 30">
                  <path
                    d="M10.5 24H30V27H10.5V24ZM0 24H4.5V27H0V24Z"
                    fill="#489E7B"
                  />
                  <path
                    d="M7.5 22.5V28.5C7.5 29.4 6.9 30 6 30H4.5C3.6 30 3 29.4 3 28.5V22.5C3 21.6 3.6 21 4.5 21H6C6.9 21 7.5 21.6 7.5 22.5ZM18 3H30V6H18V3ZM0 3H12V6H0V3Z"
                    fill="#489E7B"
                  />
                  <path
                    d="M15 1.5V7.5C15 8.4 14.4 9 13.5 9H12C11.1 9 10.5 8.4 10.5 7.5V1.5C10.5 0.6 11.1 0 12 0H13.5C14.4 0 15 0.6 15 1.5ZM25.5 13.5H30V16.5H25.5V13.5ZM0 13.5H19.5V16.5H0V13.5Z"
                    fill="#489E7B"
                  />
                  <path
                    d="M22.5 12V18C22.5 18.9 21.9 19.5 21 19.5H19.5C18.6 19.5 18 18.9 18 18V12C18 11.1 18.6 10.5 19.5 10.5H21C21.9 10.5 22.5 11.1 22.5 12Z"
                    fill="#489E7B"
                  />
                </svg>
              </button>
            </div>
            <div className="w-full">
              <label
                htmlFor="searchInput"
                className="pl-2 text-xs font-semibold text-[#787878]">
                Search All Organizations
              </label>
              <div className="relative">
                <div className="pointer-events-none absolute inset-y-0 start-0 flex items-center ps-3">
                  <svg
                    className="h-4 w-4 text-mint-medical-grey"
                    aria-hidden="true"
                    fill="none"
                    viewBox="0 0 20 20">
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                    />
                  </svg>
                </div>
                <input
                  onChange={(event) => handleSearchTextChange(event.target.value)}
                  type="search"
                  id="searchInput"
                  pattern=".{3,}"
                  value={searchInput}
                  onFocus={() => {
                    setSearchFocus(true);
                  }}
                  onBlur={() => {
                    setSearchFocus(false);
                  }}
                  className={
                    searchFocus
                      ? 'block h-12 w-full cursor-pointer rounded-e-md border-2 border-mint-medical-green p-4 ps-10 text-base text-mint-medical-grey outline-none'
                      : 'block h-12 w-full cursor-pointer rounded-e-md border-2 border-[#D2D2D2] p-4 ps-10 text-base text-mint-medical-grey outline-none'
                  }
                  // className="block h-12 w-full cursor-pointer rounded-e-md border-2 border-[#D2D2D2] p-4 ps-10 text-base text-black outline-none focus:border-mint-medical-green disabled:bg-[#F0F0F0] disabled:text-black"
                  required
                />
              </div>
            </div>
            <input
              className=" text-l ml-2 h-12 w-1/4 cursor-pointer self-end rounded-md bg-mint-medical-green text-center font-semibold text-white md:w-1/6"
              type="submit"
              value="Search"></input>
          </div>
          <div
            ref={filterFocus ? wrapperRef : null}
            className={
              filterFocus
                ? 'absolute z-50 my-1 flex w-full flex-col gap-y-2 rounded-md border-2 border-mint-medical-grey-light bg-white p-4'
                : 'absolute z-50 my-1 hidden w-full flex-col gap-y-2 rounded-md border-2 border-mint-medical-grey-light bg-white p-4'
            }>
            <span className="mb-2 text-base font-semibold text-mint-medical-grey">Filter by</span>
            <div className=" inline-flex space-x-4">
              <input
                className="h-7 w-7 rounded-md border-2 border-[#D2D2D2] accent-mint-medical-green"
                onChange={(event) => handleFilterChange(event.currentTarget.value)}
                id="all"
                type="checkbox"
                name="filter"
                checked={queryParams.filter === 'All'}
                value="All"></input>
              <label
                htmlFor="all"
                className="my-auto">
                View All
              </label>
            </div>

            <div className=" inline-flex space-x-4">
              <input
                className="h-7 w-7 rounded-md border-2 border-[#D2D2D2] accent-mint-medical-green"
                onChange={(event) => handleFilterChange(event.currentTarget.value)}
                id="pending"
                type="checkbox"
                name="filter"
                checked={queryParams.filter === 'Pending'}
                value="Pending"></input>
              <label
                htmlFor="pending"
                className="my-auto">
                Pending Approval
              </label>
            </div>

            <div className=" inline-flex space-x-4">
              <input
                className="h-7 w-7 rounded-md border-2 border-[#D2D2D2] accent-mint-medical-green"
                onChange={(event) => handleFilterChange(event.currentTarget.value)}
                id="denied"
                type="checkbox"
                name="filter"
                checked={queryParams.filter === 'Denied'}
                value="Denied"></input>
              <label
                htmlFor="denied"
                className="my-auto">
                Denied
              </label>
            </div>
          </div>
        </form>
      </div>
      <table className="w-full">
        <thead>
          <tr>
            <th
              scope="col"
              className=" max-w-fit cursor-pointer"
              onClick={() => {
                sortCompaniesByKey('status');
              }}>
              Status
            </th>
            <th
              scope="col"
              className=" cursor-pointer"
              onClick={() => {
                sortCompaniesByKey('name');
              }}>
              Organization Info
            </th>
            <th
              scope="col"
              className="hidden md:block">
              Action Needed
            </th>
            <th
              scope="col"
              className=" w-30"></th>
          </tr>
        </thead>
        <tbody className=" gap-2 divide-y-2 divide-white rounded-md">
          {dataLoader.loadingStatus != LoadingStatus.Succeed ? (
            <tr>
              <td colSpan={4}>
                {dataLoader.error || (
                  <div className="my-10 flex flex-col">
                    <InViewSpinner></InViewSpinner>
                  </div>
                )}
              </td>
            </tr>
          ) : null}

          {dataLoader.loadingStatus === LoadingStatus.Succeed && !dataLoader.data?.length && (
            <tr className="min-h-24 min-w-full bg-mint-medical-grey-light bg-opacity-25 p-4 text-center">
              <td
                className="h-12"
                colSpan={4}>
                No search results!
              </td>
            </tr>
          )}

          {dataLoader.loadingStatus === LoadingStatus.Succeed &&
            dataLoader.data!.length > 0 &&
            dataLoader
              .data!.filter((_, index) => {
                const showItemConditionResult = index >= (tabs.current - 1) * 10 && index < tabs.current * 10;

                return showItemConditionResult;
              })
              .map((company) => (
                <tr
                  className="min-h-24 min-w-full bg-mint-medical-grey-light bg-opacity-25 p-4"
                  key={company.id}>
                  <td>
                    {company.status === 'Pending' ? (
                      <div className="flex flex-row space-x-4 pl-4">
                        <PendingDot></PendingDot>
                        <span className="hidden md:inline">Pending Approval</span>
                      </div>
                    ) : null}
                    {company.status === 'Denied' ? (
                      <div className="flex flex-row space-x-4 pl-4">
                        <DeniedDot></DeniedDot>
                        <span className="hidden md:inline">Denied</span>
                      </div>
                    ) : null}
                    {company.status === 'Approved' ? (
                      <div className="flex flex-row space-x-4 pl-4">
                        <ApprovedDot></ApprovedDot>
                        <span className="hidden md:inline">Approved</span>
                      </div>
                    ) : null}
                  </td>
                  <td>
                    <div>{company.name}</div>
                    <div>
                      <CompanyManagerInfo companyId={company.id}></CompanyManagerInfo>
                    </div>
                  </td>
                  <td className="my-4 hidden space-y-4 md:flex md:flex-col">
                    {company.requiredAction && company.requiredAction.accountApprovementRequired && (
                      <CompanyActionUpdater
                        companyId={company.id}
                        onStatusChange={() => {
                          handleCompanyStatusChanged();
                        }}
                        companyName={company.name}>
                        <span className="my-auto me-2 rounded border border-mint-medical-green bg-mint-medical-green-light px-2 text-xxs font-medium text-green-800">
                          New Account
                        </span>
                      </CompanyActionUpdater>
                    )}
                    {company.requiredAction.domainApprovementRequired &&
                      company.requiredAction.domainApprovementRequired.length > 0 &&
                      !company.requiredAction.accountApprovementRequired &&
                      company.requiredAction.domainApprovementRequired.map((domain) => (
                        <CompanyDomainStatusUpdater
                          key={domain.domainName}
                          domainName={domain.domainName}
                          onStatusUpdate={handleCompanyStatusChanged}
                          companyId={company.id}>
                          <span className="my-auto me-2 rounded border border-mint-medical-green bg-mint-medical-green-light px-2 text-xxs font-medium text-green-800">
                            {domain.domainName}
                          </span>
                        </CompanyDomainStatusUpdater>
                      ))}
                  </td>
                  <td className="cursor-pointer text-center text-mint-medical-green underline md:px-4">
                    <span
                      className="font-semibold"
                      onClick={() => {
                        handleViewDetails(company);
                      }}>
                      View Details
                    </span>
                  </td>
                </tr>
              ))}
        </tbody>
        <tfoot>
          <tr>
            <td
              colSpan={4}
              className="">
              <div className="flex flex-row justify-center divide-x-2 divide-mint-medical-grey-light py-1">
                {Array.from({ length: tabs.max }, (x, i) => (
                  <span
                    key={i + 1}
                    onClick={() => {
                      if (tabs.current === i + 1) {
                        return;
                      }
                      setTabs({
                        ...tabs,
                        current: i + 1
                      });

                      setCompaniesPageInUrl(i + 1);
                    }}
                    className={tabs.current === i + 1 ? 'cursor-pointer px-2 font-semibold' : 'cursor-pointer px-2'}>
                    {i + 1}
                  </span>
                ))}
              </div>
            </td>
          </tr>
        </tfoot>
      </table>
      <OrganizationXLSXReport></OrganizationXLSXReport>
    </div>
  );
}
