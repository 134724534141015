import React from 'react';
import { ClickableProps } from '../Base';

export function DenyButton(props: ClickableProps) {
  return (
    <>
      <button
        className="flex flex-row space-x-2"
        onClick={props.onClick}>
        <svg
          width="27"
          height="27"
          viewBox="0 0 27 27"
          fill="none">
          <path
            d="M23.7857 0H3.21429C1.44 0 0 1.44 0 3.21429V23.7857C0 25.56 1.44 27 3.21429 27H23.7857C25.56 27 27 25.56 27 23.7857V3.21429C27 1.44 25.56 0 23.7857 0ZM19.7421 18.8293L18.8293 19.7421L13.5 14.4129L8.17071 19.7421L7.25786 18.8293L12.5871 13.5L7.25786 8.17071L8.17071 7.25786L13.5 12.5871L18.8293 7.25786L19.7421 8.17071L14.4129 13.5L19.7421 18.8293Z"
            fill="#D81818"
          />
        </svg>
        <span>Deny</span>
      </button>
    </>
  );
}
