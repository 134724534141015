import React from 'react';
import { Input } from '../../../../components/Input';
import { CompanyDomains } from '../CompanyDomains/CompanyDomains';
import { ICompanyDomain } from '../../../../types/CompanyDomain';
import { PasswordEditForm } from './PasswordEditForm';
import { OrganizationEditableDataForm } from '../../../OrganizationEditableDataForm/OrganizationEditableDataForm';
// import { OrganizationAddressForm } from '../../../OrganizationAddressForm/OrganizationAddressForm';

interface CompanyData {
  status: string;
  id: string;
  name: string;
  manager?: {
    email: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
  };
  domains: ICompanyDomain[];
}

export interface CompanyAccountDetailsProps {
  companyData: CompanyData;
  companyDataUpdated?: () => void;
}

export function CompanyAccountDetails(props: CompanyAccountDetailsProps) {
  return (
    <>
      <div className="flex w-full flex-col">
        <div className="flex justify-between py-4">
          <span className="font-semibold">My Information</span>
        </div>
        <div className="w-full md:w-1/2 md:pr-2">
          <Input
            type="email"
            label="Email"
            value={props.companyData.manager?.email}
            disabled={true}></Input>
        </div>
        <OrganizationEditableDataForm></OrganizationEditableDataForm>

        <PasswordEditForm></PasswordEditForm>

        {/* <OrganizationAddressForm></OrganizationAddressForm> */}

        <CompanyDomains
          blockEditingStatus={true}
          getEndpoint={'/company/domain'}
          postEndpoint={'/company/domains'}></CompanyDomains>
      </div>
    </>
  );
}
