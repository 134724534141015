import React from 'react';
import { ClickableProps } from '../Base';

export function ApproveButton(props: ClickableProps) {
  return (
    <>
      <button
        className="flex flex-row space-x-2"
        onClick={props.onClick}>
        <svg
          width="27"
          height="27"
          viewBox="0 0 27 27"
          fill="none">
          <path
            d="M22.5 0H4.5C2.01897 0 0 2.01897 0 4.5V22.5C0 24.981 2.01897 27 4.5 27H22.5C24.981 27 27 24.981 27 22.5V4.5C27 2.01897 24.981 0 22.5 0ZM12.2695 19.572L6.05441 13.7787L6.9308 12.8371L12.159 17.7137L20.7246 7.60631L21.704 8.4375L12.2695 19.572Z"
            fill="#0AA078"
          />
        </svg>
        <span>Approve</span>
      </button>
    </>
  );
}
