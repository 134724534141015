import React, { useEffect, useState } from 'react';
import { ChildrenOwnerProps } from '../components/Base';
import { AppConfig, AppConfigContext, AppConfigData } from './Config';

export interface AppConfigProviderProps extends ChildrenOwnerProps {}

enum ConfigLoadingStatus {
  Loading = 'Loading',
  Loaded = 'Loaded',
  Other = ''
}

export function AppConfigProvider(props: AppConfigProviderProps) {
  const [appConfig, setAppConfig] = useState<AppConfigData | null>(null);
  const [configLoadingStatus, setConfigLoadingStatus] = useState(ConfigLoadingStatus.Other);

  useEffect(() => {
    if (configLoadingStatus !== ConfigLoadingStatus.Loaded) {
      getConfig();
    }
  }, [appConfig]);

  async function getConfig() {
    setConfigLoadingStatus(ConfigLoadingStatus.Loading);

    try {
      const config = await AppConfig.getInstance().getConfig();
      setAppConfig(config);
      setConfigLoadingStatus(ConfigLoadingStatus.Loaded);
    } catch (error) {
      setConfigLoadingStatus(ConfigLoadingStatus.Other);
      console.error(error);
    }
  }

  return <AppConfigContext.Provider value={{ appConfig }}>{props.children}</AppConfigContext.Provider>;
}
