import React, { useState } from 'react';
import { Modal } from '../../../../components/Modal/Modal';
import { BorderedButton, FilledButton } from '../../../../components/Buttons';
import { ChildrenOwnerProps } from '../../../../components/Base';
import { ScaleLoader } from 'react-spinners';

export enum ConfirmationActions {
  Approve = 'approve',
  Deny = 'deny'
}

export interface ConfirmationModalProps extends ChildrenOwnerProps {
  noLabel?: string;
  title: string;
  yesLabel?: string;
  mainColor?: string;
  visible: boolean;
  action: ConfirmationActions;
  closeModal: () => void;
  yesMethod: () => void;
}

export function ConfirmationModal(props: ConfirmationModalProps) {
  const [spinner, setSpinner] = useState(false);

  async function handleAgreement() {
    setSpinner(true);

    try {
      await props.yesMethod();

      setSpinner(false);
    } catch (error) {
      setSpinner(false);
    }
  }

  return (
    <>
      <Modal
        closeButtonTextColor={props.action === ConfirmationActions.Approve ? 'text-mint-medical-green' : 'text-red-500'}
        closeModal={props.closeModal}
        visible={props.visible}>
        <div className="h-full w-full space-y-8">
          <div className="text-center">
            <div className="text-lg font-bold">{props.title}</div>
          </div>
          <div className="text-center">{props.children}</div>
          <div className="flex flex-row space-x-4">
            <FilledButton
              onClick={handleAgreement}
              bgColor={props.action === ConfirmationActions.Approve ? 'bg-mint-medical-green' : 'bg-red-500'}>
              {!spinner ? (
                props.yesLabel ? (
                  props.yesLabel
                ) : (
                  'Yes'
                )
              ) : (
                <ScaleLoader
                  className="mx-auto"
                  color="#fff"></ScaleLoader>
              )}
            </FilledButton>
            <BorderedButton
              borderColor={
                props.action === ConfirmationActions.Approve ? 'border-mint-medical-green' : 'border-red-500'
              }
              textColor={props.action === ConfirmationActions.Approve ? 'text-mint-medical-green' : 'text-red-500'}
              onClick={props.closeModal}>
              {props.noLabel ? props.noLabel : 'No'}
            </BorderedButton>
          </div>
        </div>
      </Modal>
    </>
  );
}
