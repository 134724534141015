import React from 'react';
import { ChildrenOwnerProps, ClickableProps } from '../Base';

interface ButtonProps extends ChildrenOwnerProps, ClickableProps {
  disabled?: boolean;
  className?: string;
  type?: 'button' | 'submit' | 'reset' | undefined;
}

export function Button(props: ButtonProps) {
  return (
    <button
      type={props.type}
      disabled={props.disabled}
      className={props.className}
      onClick={props.onClick}>
      {props.children}
    </button>
  );
}
