import React from 'react';
import mintMedicalLogoWhite from '../../assets/mint_medical_logo_white.svg';
import contactEmailDE from '../../assets/footer_mail_de.svg';
import contactEmailCom from '../../assets/footer_mail_com.svg';
import { Address, AddressWrapper } from './Address';
import { FooterLink, FooterLinksWrapper } from './FooterLink';
import { InstagramIconLink, LinkedinIconLink, NewsletterIconLink, XIconLink, YoutubeIconLink } from './Icons';

export function Footer() {
  return (
    <footer className="fg-white inset-x-0 bottom-0 h-fit bg-mint-medical-grey-dark px-4 py-8 font-sans">
      <div className="mx-auto max-w-screen-lg">
        <div className="flex flex-col items-center lg:flex-row">
          <div className="mb-4 lg:self-start">
            <img
              src={mintMedicalLogoWhite}
              width={193}
              height={80}
              alt="Mint Medical Logo"></img>
          </div>
          <div className="flex w-full flex-col sm:flex-row">
            <div className="mx-3 flex w-full flex-row justify-center text-xs text-white sm:w-1/2">
              <FooterLinksWrapper>
                <FooterLink to="https://mint-medical.com/the-company/about-us">About us</FooterLink>
                <FooterLink to="https://mint-medical.com/contact">Contact</FooterLink>
                <FooterLink to="https://mint-medical.com/the-company/career">Career</FooterLink>
                <FooterLink to="https://mint-medical.com/imprint">Imprint</FooterLink>
              </FooterLinksWrapper>
              <FooterLinksWrapper>
                <FooterLink to="https://mint-medical.com/eula">EULA</FooterLink>
                <FooterLink to="/document/privacy_policy">Privacy policy</FooterLink>
                <FooterLink to="https://mint-medical.com/compliance">Compliance</FooterLink>
                <FooterLink to="/document/terms_and_conditions">Terms & Conditions</FooterLink>
              </FooterLinksWrapper>
            </div>
            <div className="w-fit self-center text-xs text-white sm:w-1/2">
              <div className="mb-3 w-fit font-bold text-white">Contact us</div>
              <div className="flex w-full flex-row space-x-1">
                <AddressWrapper>
                  <Address
                    line1="Mint Medical GmbH"
                    line2="Burgstraße 61"
                    line3="69121  Heidelberg,"
                    country="Germany"
                    phoneNumber="+49 6221 321 80 0"
                    email={contactEmailDE}></Address>
                </AddressWrapper>
                <AddressWrapper>
                  <Address
                    line1="Mint Medical Inc"
                    line2="100 Horizon Center Blvd"
                    line3="Hamilton  NJ  08691,"
                    country="USA"
                    phoneNumber="+1 844 200 MINT"
                    email={contactEmailCom}></Address>
                </AddressWrapper>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="flex justify-center pt-4 text-white">
            <div>
              <div className="mb-2 flex justify-between">
                <NewsletterIconLink></NewsletterIconLink>
                <LinkedinIconLink></LinkedinIconLink>
                <InstagramIconLink></InstagramIconLink>
                <YoutubeIconLink></YoutubeIconLink>
                <XIconLink></XIconLink>
              </div>
              <div className="text-xxs uppercase text-white text-opacity-50">
                <span>© Copyright Mint Medical 2024 | All rights reserved</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
