import React from 'react';
import { ChildrenOwnerProps } from '../../../../components/Base';

export function TierWrapper(props: ChildrenOwnerProps) {
  return (
    <div className="flex">
      <div className="mx-auto overflow-x-auto">
        <div className="flex w-full space-x-2">{props.children}</div>
      </div>
    </div>
  );
}
