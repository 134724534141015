import React from 'react';
import { Link } from 'react-router-dom';

export function InstagramIconLink() {
  return (
    <Link
      to="https://www.instagram.com/mintmedical/"
      target="_blank">
      <svg
        version="1.1"
        id="Layer_1"
        x="0px"
        y="0px"
        width="42px"
        height="42px"
        viewBox="0 0 42 42"
        enableBackground="new 0 0 42 42"
        className=" fill-white hover:fill-mint-medical-green">
        <path
          id="Combined-Shape"
          d="M20,2c9.941,0,18,8.059,18,18c0,9.941-8.059,18-18,18c-9.941,0-18-8.059-18-18
                C2,10.059,10.059,2,20,2z M24.905,7h-9.811C10.102,7,7,10.102,7,15.094v9.811C7,29.898,10.102,33,15.094,33h9.811
                C29.898,33,33,29.898,33,24.905v-9.811C33,10.102,29.898,7,24.905,7z M30.547,24.905c0,3.639-2.003,5.642-5.642,5.642h-9.811
                c-3.638,0-5.642-2.003-5.642-5.642v-9.811c0-3.638,2.003-5.642,5.642-5.642h9.811c3.639,0,5.642,2.003,5.642,5.642V24.905z
                M20,13.302c-3.693,0-6.698,3.004-6.698,6.698c0,3.692,3.004,6.697,6.698,6.697c3.692,0,6.697-3.005,6.697-6.697
                C26.697,16.307,23.692,13.302,20,13.302z M20,24.291c-2.366,0-4.291-1.926-4.291-4.291c0-2.366,1.925-4.291,4.291-4.291
                c2.365,0,4.291,1.925,4.291,4.291C24.291,22.365,22.365,24.291,20,24.291z M26.917,11.513c-0.866,0-1.569,0.703-1.569,1.57
                c0,0.867,0.703,1.57,1.569,1.57s1.569-0.703,1.569-1.57C28.486,12.216,27.783,11.513,26.917,11.513z"
        />
      </svg>
    </Link>
  );
}

export function LinkedinIconLink() {
  return (
    <Link
      to="https://www.linkedin.com/company/3244077"
      target="_blank">
      <svg
        width="42px"
        height="42px"
        viewBox="0 0 42 42"
        version="1.1"
        className=" fill-white hover:fill-mint-medical-green">
        <path
          d="M20,2 C29.9411255,2 38,10.0588745 38,20 C38,29.9411255 29.9411255,38 20,38 C10.0588745,38 2,29.9411255 2,20 C2,10.0588745 10.0588745,2 20,2 Z M15,16 L11.75,16 C11.4738576,16 11.25,16.2238576 11.25,16.5 L11.25,16.5 L11.25,28 C11.25,28.2761424 11.4738576,28.5 11.75,28.5 L11.75,28.5 L15,28.5 C15.2761424,28.5 15.5,28.2761424 15.5,28 L15.5,28 L15.5,16.5 C15.5,16.2238576 15.2761424,16 15,16 L15,16 Z M28.468219,17.0203655 C26.9327313,15.8283988 25.4086382,15.694812 24.0847619,16.0609933 C23.2757263,16.2847708 22.5543572,16.7840612 21.9206545,17.5588645 L21.9206545,17.5588645 L21.75,17.7751617 L21.75,16.5 C21.75,16.2238576 21.5261424,16 21.25,16 L21.25,16 L18.25,16 C17.9738576,16 17.75,16.2238576 17.75,16.5 L17.75,16.5 L17.75,28 C17.75,28.2761424 17.9738576,28.5 18.25,28.5 L18.25,28.5 L21.25,28.5 C21.5261424,28.5 21.75,28.2761424 21.75,28 L21.75,28 L21.7501727,21.7600223 C21.7643691,20.0958006 22.6524266,19.1216661 23.9504987,19.1216661 C25.2590922,19.1216661 25.875,20.2624594 25.875,21.3157779 C25.875,21.9894554 25.9133492,24.2209024 25.9900475,28.0101188 C25.9955562,28.2822687 26.217752,28.5000126 26.4899577,28.5 L26.4899577,28.5 L29.5000442,28.5 C29.7761866,28.5 30.0000442,28.2761424 30.0000442,28 L30.0000442,28 L30.0000442,21.3157779 C30.0024859,19.2468141 29.4918775,17.81501 28.468219,17.0203655 Z M13.25,10.25 C12.0073593,10.25 11,11.2573593 11,12.5 C11,13.7426407 12.0073593,14.75 13.25,14.75 C14.4926407,14.75 15.5,13.7426407 15.5,12.5 C15.5,11.2573593 14.4926407,10.25 13.25,10.25 Z"
          id="Combined-Shape"></path>
      </svg>
    </Link>
  );
}

export function NewsletterIconLink() {
  return (
    <Link
      to="https://mint-medical.com/news/newsletter-subscription"
      target="_blank"
      className=" fill-white hover:fill-mint-medical-green">
      <svg
        width="42px"
        height="42px"
        viewBox="0 0 42 42"
        version="1.1"
        className="fill-white hover:fill-mint-medical-green">
        <path d="M27.9,13H12.1c-0.6,0-1.1,0.5-1.1,1.1v0.5l8.5,5.4c0.3,0.2,0.8,0.2,1.1,0l8.5-5.4v-0.5C29,13.5,28.5,13,27.9,13z" />
        <path d="M20,21.9c-0.5,0-1-0.1-1.5-0.4L11,16.7v9.3c0,0.6,0.5,1.1,1.1,1.1h15.9c0.6,0,1.1-0.5,1.1-1.1v-9.3l-7.5,4.8 C21,21.7,20.5,21.9,20,21.9z" />
        <path d="M20,2C10.1,2,2,10.1,2,20s8.1,18,18,18s18-8.1,18-18S29.9,2,20,2z M30.8,25.9c0,1.6-1.3,2.9-2.9,2.9H12.1c-1.6,0-2.9-1.3-2.9-2.9V14.1c0-1.6,1.3-2.9,2.9-2.9h15.9c1.6,0,2.9,1.3,2.9,2.9V25.9z" />
      </svg>
    </Link>
  );
}

export function XIconLink() {
  return (
    <Link
      to="https://x.com/mintmedical"
      target="_blank">
      <svg
        width="42px"
        height="42px"
        viewBox="0 0 42 42"
        version="1.1"
        className="fill-white hover:fill-mint-medical-green">
        <polygon points="20.2,18.3 15,11 12.3,11 18.7,20.1 19.5,21.3 19.5,21.3 25,29.1 27.7,29.1 21,19.5 " />
        <path
          d="M20,2C10.1,2,2,10.1,2,20c0,9.9,8.1,18,18,18s18-8.1,18-18C38,10.1,29.9,2,20,2z M24.1,30.4l-5.5-8.1l-6.9,8.1
              H9.8l7.9-9.2L9.8,9.6h6.1l5.2,7.6l6.6-7.6h1.8l-7.6,8.8h0l8.2,12H24.1z"
        />
      </svg>
    </Link>
  );
}

export function YoutubeIconLink() {
  return (
    <Link
      to="https://www.youtube.com/channel/UCkgNcCcYYJQsXERg6HINUnA"
      target="_blank">
      <svg
        width="42px"
        height="42px"
        viewBox="0 0 42 42"
        version="1.1"
        className="fill-white hover:fill-mint-medical-green">
        <path
          d="M20,38 C29.9411255,38 38,29.9411255 38,20 C38,10.0588745 29.9411255,2 20,2 C10.0588745,2 2,10.0588745 2,20 C2,29.9411255 10.0588745,38 20,38 Z M27.9351963,12.2157213 C30.0426618,12.4314426 30.75182,13.5140825 31,15.7293254 C31.24818,17.9445682 31.24818,21.7791127 31,24 C30.75182,26.2208873 29.6500395,27.5874546 27.4100386,27.7938583 C26.3681776,27.88986 23.5645513,27.9411531 20.6351719,27.9478166 L19.3139844,27.9478029 C16.3836894,27.9410794 13.5730432,27.8897381 12.5180581,27.7938583 C10.2498401,27.5877165 9.23146788,26.2483033 9,24 C8.76853212,21.7516967 8.76853212,18.3319061 9,16 C9.23146788,13.6680939 10.2457763,12.4223439 12.637152,12.2157213 C13.7808535,12.1169018 16.8477063,12.0632626 19.9671163,12.0577898 L21.2414272,12.0582455 C24.1995254,12.0656136 26.9730924,12.1172398 27.9351963,12.2157213 Z M17.75,15.5 L17.75,24.5 L24.4499087,20 L17.75,15.5 Z"
          id="Combined-Shape"></path>
      </svg>
    </Link>
  );
}
