import React, { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { fetchAuthSession } from '@aws-amplify/core';
import { MintAdminAccount } from '../features/MintAdminAccount/components/MintAdminAccount/MintAdminAccount';
import { CompanyManagerAccount } from '../features/CompanyManagerAccount/components/CompanyManagerAccount/CompanyManagerAccount';
import { signOut } from '@aws-amplify/auth';
import { CompanyMember } from '../features/CompanyMember/CompanyMember';
import { FullScreenSpinner } from '../features/OrderForm/components/OrderForm/FullScreenSpinner';
import { useUserData } from '../hooks/useUserData';

enum UserTypes {
  MintAdmin = 'MintAdmin',
  CompanyManager = 'CompanyManager',
  CompanyMember = 'CompanyMember'
}

export function Account() {
  const { userData, idToken } = useUserData();
  console.log(userData, idToken);
  const { authStatus } = useAuthenticator();
  const [userType, setUserType] = useState<UserTypes>();
  const location = useLocation();

  if (authStatus === 'authenticated') {
    if (!userType) {
      fetchAuthSession().then(async (authSession) => {
        try {
          const payload = authSession.tokens?.idToken?.payload;

          const groups = JSON.parse(JSON.stringify(payload))['cognito:groups'] as Array<string>;

          if (!groups) {
            throw 'No groups';
          }

          const isAdmin = groups.includes('MintAdmins');
          const isCompanyManager = groups.includes('ManagersOfCompanies');
          const isCompanyMember = groups.includes('EmployeesOfCompanies');

          if (isAdmin) {
            setUserType(UserTypes.MintAdmin);
          } else if (isCompanyManager) {
            setUserType(UserTypes.CompanyManager);
          } else if (isCompanyMember) {
            setUserType(UserTypes.CompanyMember);
          } else {
            throw 'Unknown user type!';
          }
        } catch (error) {
          console.log(error);
          await signOut();
        }
      });
    }
  }

  if (authStatus === 'configuring' || (authStatus === 'authenticated' && !userType)) {
    return <FullScreenSpinner></FullScreenSpinner>;
  }

  if (userType == UserTypes.CompanyManager) {
    return <CompanyManagerAccount userEmail={userData?.email}></CompanyManagerAccount>;
  }

  if (userType == UserTypes.MintAdmin) {
    return <MintAdminAccount></MintAdminAccount>;
  }

  if (userType == UserTypes.CompanyMember) {
    return <CompanyMember></CompanyMember>;
  }

  return (
    <Navigate
      to="/auth/sign_in"
      state={{ location }}
      replace></Navigate>
  );
}
