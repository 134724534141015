// import { CardNumberElement } from '@stripe/react-stripe-js';
import { StripeAddressElement, StripeElements } from '@stripe/stripe-js';
import React, { useEffect } from 'react';

interface AddressProps {
  elements: StripeElements;
  defaultValues?: object;
  onInit?: (stripeAddress: StripeAddressElement) => void;
}

export function Address(props: AddressProps) {
  const addressRef: React.RefObject<HTMLDivElement> = React.createRef();

  useEffect(() => {
    props.elements!.update({
      locale: 'en',
      appearance: {
        disableAnimations: true,
        rules: {
          '.Label': {
            color: '#787878',
            fontWeight: '600',
            fontSize: '0.75rem',
            lineHeight: '1rem'
          },
          '.Input': {
            color: '#000000',
            fontSize: '1rem',
            lineHeight: '1.5rem',
            borderWidth: '2px',
            borderColor: '#D2D2D2',
            outline: 'none',
            outlineOffset: '0px',
            boxShadow: 'none'
          },
          '.Input:focus': {
            outline: '2px solid #DAECE5',
            outlineOffset: '2px',
            borderColor: '#489E7B'
          },
          '.Input:disabled': {
            color: '#F0F0F0',
            borderColor: '#D2D2D2',
            outline: 'none',
            outlineOffset: '0px',
            boxShadow: 'none'
          },
          '.Input::placeholder': {
            color: '#787878'
          }
        },

        variables: {
          colorPrimary: '#ffffff'
        }
      }
    });

    console.log(props.defaultValues);
    const addressElement = props.elements!.create('address', {
      mode: 'billing',
      defaultValues: props.defaultValues
    });
    addressElement!.mount('#address');

    props.onInit && props.onInit(addressElement);

    return () => {
      addressElement.destroy();
    };
  }, []);

  return (
    <>
      <div
        className=""
        ref={addressRef}
        id="address"></div>
    </>
  );
}
