import React, { useEffect, useState } from 'react';
import { get } from '../../../../libs/Requests';
import { DataLoader, LoadingStatus } from '../../../../types/DataLoader';
import { InViewSpinner } from '../../../../components/Spinners/InViewSpinner';
import { InvoiceModal } from './InvoiceModal';
import { LocalizedPrice } from '../../../../components/Price/Price';

export interface Purchase {
  id: string;
  amount: number;
  amount_paid: number;
  created: number;
  currency: string;
  number: string;
  status: string;
  invoice_pdf: string;
  payment_method: string;
}

export interface CompanyPurchaseHistoryDataLoader extends DataLoader {
  data?: Purchase[];
}

export function CompanyPurchaseHistory() {
  const [dataLoader, setDataLoader] = useState<CompanyPurchaseHistoryDataLoader>({
    loadingStatus: LoadingStatus.Loading
  });
  const [invoiceId, setInvoiceId] = useState('');

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    setDataLoader({ loadingStatus: LoadingStatus.Loading });

    get('/company/invoices', { signal })
      .then(async (companyPurchaseHistoryResponse) => {
        const companyPurchasesHistoryData = await companyPurchaseHistoryResponse.json();

        setDataLoader({
          ...dataLoader,
          data: companyPurchasesHistoryData as Purchase[],
          loadingStatus: LoadingStatus.Succeed
        });
      })
      .catch((error) => {
        setDataLoader({
          ...dataLoader,
          error: error.name === 'AbortError' ? undefined : 'Oops, some problems occurred during loading...',
          loadingStatus: LoadingStatus.Failed
        });
      });

    return () => {
      abortController.abort();
    };
  }, []);

  return (
    <>
      {invoiceId && (
        <InvoiceModal
          onCloseClick={() => {
            setInvoiceId('');
          }}
          invoiceId={invoiceId}></InvoiceModal>
      )}
      <div className="pt-4">
        <table className="mx-auto w-full pt-10 md:w-5/6">
          <thead>
            <tr className=" font-semibold">
              <td className="w-1/5">Invoice Number</td>
              <td className="w-1/5">Date Paid</td>
              <td className="w-1/5">Amount</td>
              <td className="w-1/5">Method</td>
              <td className="w-1/5">Status</td>
            </tr>
          </thead>
          <tbody className=" divide-y-2">
            {dataLoader.loadingStatus !== LoadingStatus.Succeed ? (
              <tr className="h-16 text-center">
                <td colSpan={5}>{dataLoader.error || <InViewSpinner></InViewSpinner>}</td>
              </tr>
            ) : null}
            {dataLoader.loadingStatus === LoadingStatus.Succeed && dataLoader.data!.length === 0 ? (
              <tr className=" h-16">
                <td
                  colSpan={5}
                  className="text-center">
                  Purchase history is empty.
                </td>
              </tr>
            ) : null}
            {dataLoader.loadingStatus === LoadingStatus.Succeed &&
              dataLoader.data!.map((row) => {
                if (row.status != 'paid') {
                  return;
                }

                return (
                  <tr
                    key={row.id}
                    className=" h-16">
                    <td className=" cursor-pointer font-semibold text-mint-medical-green hover:underline">
                      <div
                        onClick={() => {
                          setInvoiceId(row.id);
                        }}>
                        {row.number}
                      </div>
                    </td>
                    <td>{new Date(row.created * 1000).toDateString()}</td>
                    <td>
                      <LocalizedPrice
                        valueInCents={row.amount_paid}
                        currency={row.currency}></LocalizedPrice>
                    </td>
                    <td>{row.payment_method}</td>
                    <td>{row.status.toUpperCase()}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </>
  );
}
