import { SignInInput, SignInOutput, SignUpOutput, confirmSignIn, confirmSignUp, signIn } from '@aws-amplify/auth';
import React, { useState } from 'react';
import { SignUpConfirmationCodeDTO, SignUpConfirmationForm } from '../SignUpConfirmationForm/SignUpConfirmationForm';
import { SmsCodeDTO, SmsCodeForm } from '../SmsCodeForm';
import { Description } from '../../../../components/Description';
import { SignInDTO, SignInForm } from '../SignInForm';
import { TOTPCodeDTO, TOTPCodeForm } from '../TOTPCodeForm/TOTPCodeForm';
import { TOTPSetupForm } from '../TOTPSetupForm/TOTPSetupForm';
import { SignUpState, SignInState } from '../../types/';
import {
  SignInConfirmationWithNewPasswordDTO,
  SignInConfirmationWithNewPasswordForm
} from '../SignInConfirmationWithNewPassword/SignInConfirmationWithNewPassword';
import { OTPCodeDTO, OTPCodeForm } from '../OTPCodeForm';
import { FullScreenSpinner } from '../../../OrderForm/components/OrderForm/FullScreenSpinner';

export interface SignInProps {
  onSignIn: () => void;
}

export function SignIn(props: SignInProps) {
  const [fullScreenSpinnerOnScreen, setFullScreenSpinnerOnScreen] = useState(false);
  const [signInEmail, setSignInEmail] = useState('');
  const [signInState, setSignInState] = useState<SignInState | SignUpState>(SignInState.SIGN_IN);
  const [signInStateData, setSignInStateData] = useState<unknown>();
  const [formError, setFormError] = useState<unknown>('');

  function handleSignUpNextSteps(output: SignUpOutput) {
    const { nextStep } = output;

    if (nextStep.signUpStep === SignUpState.DONE) {
      setSignInState(SignInState.SIGN_IN);
    }
  }

  function handleSignInNextSteps(output: SignInOutput, additionalData?: { email?: string }) {
    const { nextStep } = output;

    if (nextStep.signInStep === SignInState.DONE) {
      setSignInState(SignInState.DONE);

      props.onSignIn();
    }

    if (nextStep.signInStep === SignInState.CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED) {
      setSignInState(SignInState.CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED);
    }

    if (nextStep.signInStep === SignInState.CONTINUE_SIGN_IN_WITH_TOTP_SETUP) {
      setSignInState(SignInState.CONTINUE_SIGN_IN_WITH_TOTP_SETUP);

      const totpSetupDetails = nextStep.totpSetupDetails;
      const appName = 'mintRexStore';
      const setupUri = totpSetupDetails.getSetupUri(appName, additionalData?.email || signInEmail);

      setSignInStateData(setupUri.href);
    }

    if (nextStep.signInStep === SignInState.CONFIRM_SIGN_IN_WITH_SMS_CODE) {
      setSignInState(SignInState.CONFIRM_SIGN_IN_WITH_SMS_CODE);

      setSignInStateData(nextStep.codeDeliveryDetails?.destination);
      console.log(nextStep.codeDeliveryDetails);
    }

    if (nextStep.signInStep === SignInState.CONFIRM_SIGN_IN_WITH_TOTP_CODE) {
      setSignInState(SignInState.CONFIRM_SIGN_IN_WITH_TOTP_CODE);
    }

    if (nextStep.signInStep === SignInState.CONFIRM_SIGN_UP) {
      setSignInState(SignInState.CONFIRM_SIGN_UP);
    }

    if (nextStep.signInStep === SignInState.CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE) {
      setSignInState(SignInState.CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE);
      setSignInStateData(nextStep.additionalInfo!.email);
    }
  }

  async function handleSignInWithNewPasswordConfirmation(formData: SignInConfirmationWithNewPasswordDTO) {
    setFullScreenSpinnerOnScreen(true);
    setFormError('');

    try {
      const resp = await confirmSignIn({
        challengeResponse: formData.password
      });

      setFullScreenSpinnerOnScreen(false);
      handleSignInNextSteps(resp);
    } catch (error) {
      setFullScreenSpinnerOnScreen(false);
      showFormError(error);
    }
  }

  async function handleSignUpConfirmation(formData: SignUpConfirmationCodeDTO) {
    setFullScreenSpinnerOnScreen(true);
    setFormError('');

    const code = formData.code;

    try {
      const res = await confirmSignUp({
        username: signInEmail,
        confirmationCode: code
      });

      setFullScreenSpinnerOnScreen(false);
      handleSignUpNextSteps(res);
    } catch (error) {
      setFullScreenSpinnerOnScreen(false);
      showFormError(error);
    }
  }

  async function handleSmsCode(formData: SmsCodeDTO) {
    setFullScreenSpinnerOnScreen(true);
    setFormError('');

    const code = formData.code;

    try {
      const res = confirmSignIn({ challengeResponse: code });

      setFullScreenSpinnerOnScreen(false);
      console.log(res);
    } catch (error) {
      setFullScreenSpinnerOnScreen(false);
      showFormError(error);
    }
  }

  async function handleOTPCode(formData: OTPCodeDTO) {
    setFullScreenSpinnerOnScreen(true);
    setFormError('');

    const code = formData.code;

    try {
      const res = await confirmSignIn({
        challengeResponse: code
      });
      setFullScreenSpinnerOnScreen(false);
      handleSignInNextSteps(res);
    } catch (error) {
      setFullScreenSpinnerOnScreen(false);
      showFormError(error);
    }
  }

  async function handleTOTPCode(formData: TOTPCodeDTO) {
    setFullScreenSpinnerOnScreen(true);
    setFormError('');

    const code = formData.code;

    try {
      const res = await confirmSignIn({
        challengeResponse: code
      });
      setFullScreenSpinnerOnScreen(false);
      handleSignInNextSteps(res);
    } catch (error) {
      setFullScreenSpinnerOnScreen(false);
      showFormError(error);
    }
  }

  async function handleLogIn(formData: SignInDTO) {
    setFullScreenSpinnerOnScreen(true);
    setFormError('');

    const password = formData.password;
    const email = formData.email;

    try {
      const signInInput: SignInInput = {
        username: email,
        password: password,
        options: {
          authFlowType: 'CUSTOM_WITH_SRP'
        }
      };

      const user = await signIn(signInInput);

      setFullScreenSpinnerOnScreen(false);

      setSignInEmail(email);

      handleSignInNextSteps(user, {
        email
      });
    } catch (error) {
      setFullScreenSpinnerOnScreen(false);
      showFormError(error);
    }

    return;
  }

  function showFormError(error: unknown) {
    const errorText = `${error}`.split('tion: ').pop();

    setFormError(errorText);
  }

  return (
    <>
      {fullScreenSpinnerOnScreen && <FullScreenSpinner></FullScreenSpinner>}

      {signInState === SignInState.CONFIRM_SIGN_IN_WITH_SMS_CODE && (
        <Description>
          <>
            We have delivered the authentication code by SMS to {signInStateData}. Please enter the code below to
            complete authentication.
          </>
        </Description>
      )}

      {signInState === SignInState.CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE && (
        <Description>
          <>
            We have delivered the authentication code by email to {signInStateData}. Please enter the code below to
            complete authentication.
          </>
        </Description>
      )}

      {signInState === SignInState.CONFIRM_SIGN_IN_WITH_TOTP_CODE && (
        <Description>
          Some random message about TOTP apps like Microsoft Authenticator or Google Authenticator.
        </Description>
      )}

      {signInState === SignInState.CONTINUE_SIGN_IN_WITH_TOTP_SETUP && (
        <Description>Scan QR code using TOTP app like Microsoft Authenticator or Google Authenticator.</Description>
      )}

      {signInState === SignInState.CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED && (
        <Description>Set up new passwords.</Description>
      )}

      <div className="mx-auto w-full bg-white md:w-2/6">
        {signInState === SignInState.SIGN_IN && (
          <SignInForm
            onSubmit={handleLogIn}
            submissionError={formError}></SignInForm>
        )}
        {signInState === SignInState.CONFIRM_SIGN_IN_WITH_SMS_CODE && (
          <SmsCodeForm
            submissionError={formError}
            onSubmit={handleSmsCode}></SmsCodeForm>
        )}
        {signInState === SignInState.CONFIRM_SIGN_IN_WITH_TOTP_CODE && (
          <TOTPCodeForm
            submissionError={formError}
            onSubmit={handleTOTPCode}></TOTPCodeForm>
        )}
        {signInState === SignInState.CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE && (
          <OTPCodeForm
            submissionError={formError}
            onSubmit={handleOTPCode}></OTPCodeForm>
        )}
        {signInState === SignInState.CONTINUE_SIGN_IN_WITH_TOTP_SETUP && (
          <TOTPSetupForm
            totpSetupLink={signInStateData as string}
            submissionError={formError}
            onSubmit={handleTOTPCode}></TOTPSetupForm>
        )}

        {signInState === SignInState.CONFIRM_SIGN_UP && (
          <SignUpConfirmationForm
            submissionError={formError}
            onSubmit={handleSignUpConfirmation}></SignUpConfirmationForm>
        )}

        {signInState === SignInState.CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED && (
          <SignInConfirmationWithNewPasswordForm
            submissionError={formError}
            onSubmit={handleSignInWithNewPasswordConfirmation}></SignInConfirmationWithNewPasswordForm>
        )}
      </div>
    </>
  );
}
